import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;
const xsm = defaultValues.isMdSmall;

export const styles = {
  container: (style) => ({
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: sm ? 0.5 : 1.5,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    ...style
  }),
  subCont: (myOrdersCard) => ({
    width: '100%',
    display: 'flex',
    justifyContent: myOrdersCard ? 'space-evenly' : 'space-between',
    flexDirection: xsm ? 'column' : 'row',
    gap: myOrdersCard ? res && '10px' : '20px'
  }),
  detailsCont: (myOrdersCard) => ({
    width: xsm ? '100%' : res ? 'calc(100% - 110px)' : '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: res ? (myOrdersCard ? '8px' : '10px') : '20px',
    alignItems: myOrdersCard && !res && 'center'
  }),
  productNameCont: {
    mt: res ? 0 : 1
  },
  img: {
    width: sm ? '70px' : res ? '110px' : '150px',
    height: sm ? '70px' : res ? '110px' : '150px'
  },
  imgCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: res ? '10px' : '20px'
  },
  counterCont: {
    width: sm ? '70px' : res ? '110px' : '150px',
    justifyContent: 'space-between'
  },
  priceCont: (myOrdersCard) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: myOrdersCard && 'center',
    mt: myOrdersCard ? 0 : 2,
    width: myOrdersCard ? '15%' : '10%'
  }),
  closeIcon: {
    color: color.errorText,
    cursor: 'pointer',
    ml: 'auto',
    fontSize: sm ? '15px' : '25px'
  },
  materialCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  detailsCommonSubCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  totalCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: '10px'
  },
  secondaryText: {
    color: color.secondaryText
  },
  priceText: {
    color: color.palette.royalBlue
  },
  outOfStock: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%'
  }
};
