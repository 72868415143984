import { defaultValues } from '../../constants';

export const styles = {
  headertxtStyle: {
    whiteSpace: 'pre-wrap'
  },
  tableSubCont: {
    overflow: 'hidden'
  },
  columnWidth: '14%',
  tableItemProductName: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: defaultValues.isResponsive ? '18px' : '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  textOverFlow: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
};
