import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage, SCREEN_PATH } from '../../constants';
import { ActionButton, EditButton, NavigationText, Screen, Text, UILayout } from '../../ui-kits';
import { checkReadOnly, getTitles, resetMessages } from '../../utils';
import { useNavigate } from 'react-router-dom';
import {
  getMasterListWiseRequest,
  getProductVariantsRequest,
  resetManageProcessMsg,
  updateDetailsPg,
  updateProductVariantRequest
} from '../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurPage,
  getErrorMsg,
  getProductVariants,
  getSelectedProduct,
  getSuccessMsg,
  getTotalPage,
  processLoading
} from '../../services/redux/selectors/manageProccess';
import { getUserData } from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { styles } from './styles';

export const ManageVariants = () => {
  const isRes = defaultValues.isResponsive;
  const { add, view, edit } = defaultValues.actionType;
  const { processManager } = defaultValues.employeeRol;
  const { temperatureCondition, cowBreed, cowVariety, buffaloBreed } = defaultValues.mListIds;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const product = useSelector(getSelectedProduct);
  const userData = useSelector(getUserData);
  const totalPages = useSelector(getTotalPage);
  const currentPage = useSelector(getCurPage);
  const variantsList = useSelector(getProductVariants);
  const success = useSelector(getSuccessMsg);

  const error = useSelector(getErrorMsg);
  const isLoading = useSelector(processLoading);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    dispatch(getProductVariantsRequest({ product_master_id: product?.product_id, sort: 'latest' }));
  }, []);

  useEffect(() => {
    if (variantsList) {
      setVariants(variantsList);
    }
  }, [variantsList]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, processManager));
  }, [userData]);

  useEffect(() => {
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([temperatureCondition, cowBreed, cowVariety, buffaloBreed])
      })
    );
  }, []);

  const tableData = variants?.map((item) => {
    if (!isRes) {
      return [
        {
          title: getTitles('T-10438'),
          columnWidth: styles.columnWidth,
          values: <Text variant="BASE">{item?.variant_id}</Text>
        },
        {
          title: getTitles('T-11745'),
          columnWidth: !isReadOnly && '20%',
          values: (
            <Text style={styles.tableItemProductName} onClick={() => handleViewVariants(item)}>
              {item?.variant_name}
            </Text>
          )
        },
        {
          title: getTitles('T-11525'),
          columnWidth: !isReadOnly && '20%',
          values: (
            <Text variant="BASE" style={styles.textOverFlow}>
              {item?.sale_price}
            </Text>
          )
        },
        {
          title: getTitles('T-11442'),
          columnWidth: !isReadOnly && '20%',
          values: (
            <Text variant="BASE" style={styles.textOverFlow}>
              {item?.min_stock_qty}
            </Text>
          )
        },
        {
          title: getTitles('T-10103'),
          columnWidth: '15%',
          values: (
            <ActionButton
              alterTitle={defaultValues.confirmationModel.changeProductStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeProductStatus.subtitles}
              status={item?.is_active}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
              isReadOnly={isReadOnly}
            />
          )
        },

        !isReadOnly && {
          title: getTitles('T-10084'),
          columnWidth: '12%',
          values: <EditButton status={item.is_active} onClick={() => handleEditButton(item)} />
        }
      ];
    } else {
      return {
        title: (
          <Text style={styles.tableItemProductName} onClick={() => handleViewVariants(item)}>
            {item?.variant_name}
          </Text>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: <Text variant="D20">{item?.product_id}</Text>
          },
          {
            subTitle: getTitles('T-11525'),
            action: (
              <Text variant="D20" style={styles.textOverFlow}>
                {item?.sale_price}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-11442'),
            action: (
              <Text variant="BASE" style={styles.textOverFlow} onClick={() => {}}>
                {item?.min_stock_qty}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                alterTitle={defaultValues.confirmationModel.changeProductStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeProductStatus.subtitles}
                status={item?.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                isReadOnly={isReadOnly}
              />
            )
          },
          !isReadOnly && {
            subTitle: getTitles('T-10084'),
            action: <EditButton status={item?.is_active} onClick={() => handleEditButton(item)} />
          }
        ]
      };
    }
  });

  const handleSearch = (data) => {
    dispatch(
      getProductVariantsRequest({ product_master_id: product?.product_id, search_text: data })
    );
  };

  const handleRefresh = () => {
    setSort('');
    setFilter([]);
    dispatch(getProductVariantsRequest({ product_master_id: product?.product_id, sort: 'latest' }));
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(
      getProductVariantsRequest({
        product_master_id: product?.product_id,
        sort: data,
        filter: JSON.stringify(filter)
      })
    );
  };

  const handleFilter = (data) => {
    setFilter(data);
    dispatch(
      getProductVariantsRequest({
        product_master_id: product?.product_id,
        filter: JSON.stringify(data),
        sort: sort
      })
    );
  };

  const handlePagination = (e, data) => {
    dispatch(getProductVariantsRequest({ product_master_id: product?.product_id, page: data }));
  };

  const handleAddButton = () => {
    dispatch(updateDetailsPg(true));
    navigate(SCREEN_PATH.ADD_VARIENTS, { state: { actionType: add, isReadOnly } });
  };

  const handleStatus = (_id, is_active) => {
    dispatch(
      updateProductVariantRequest({
        _id,
        is_active: !is_active
      })
    );
  };

  const handleEditButton = (variant) => {
    dispatch(updateDetailsPg(true));
    navigate(SCREEN_PATH.ADD_VARIENTS, { state: { actionType: edit, data: variant, isReadOnly } });
  };

  const handleViewVariants = (variant) => {
    dispatch(updateDetailsPg(true));
    navigate(SCREEN_PATH.ADD_VARIENTS, { state: { actionType: view, data: variant, isReadOnly } });
  };

  useEffect(() => {
    if (success === 'S-10146') {
      dispatch(getProductVariantsRequest({ product_master_id: product?.product_id }));
      toast.success(getSuccessMessage(success));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [success, error]);

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11744')}>
      <NavigationText
        list={[{ title: product?.name, route: 'goBack' }, { title: getTitles('T-11742') }]}
      />
      <UILayout
        isLoading={isLoading}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-10308')}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        optionsForSorting={defaultValues.farmerSortOptions}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        optionsForFiltering={defaultValues.commonFilterOption}
        isMultiSelectFilterOption={true}
        handleFilter={handleFilter}
        handleAddButton={!isReadOnly && handleAddButton}
        tableItems={tableData}
        tableHeaderTextStyle={styles.headertxtStyle}
        tableSuperSubCont={styles.tableSubCont}
        showPagination={totalPages > 1}
        pageCount={totalPages}
        currPage={currentPage}
        showNoData={!variants.length > 0}
        showDataTable={!isRes}
        handlePagination={handlePagination}
        message={getErrorMessage('E-10303')}
      />
    </Screen>
  );
};
