import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  success: null,
  error: null,
  states: [],
  currentPage: null,
  nextPage: null,
  totalPage: null,
  district: [],
  taluka: [],
  village: [],
  languageList: [],
  masterList: [],
  diseaseList: [],
  diseaseGroup: [],
  diseaseType: [],
  currentTab: 0,
  masterId: null,
  languageParams: {},
  diseaseParams: {},
  activePage: null,
  activeDistrictPage: null,
  activeTalukaPage: null,
  activeVillagePage: null,
  stateNavDetails: null,
  stateNxtPage: null,
  stateCurrPage: null,
  stateTotalPage: null,
  districtNxtPage: null,
  districtCurrPage: null,
  districtTotalPage: null,
  talukaNxtPage: null,
  talukaCurrPage: null,
  talukaTotalPage: null,
  villageNxtPage: null,
  villageCurrentPage: null,
  villageTotalPage: null,
  vaccines: [],
  currVaccinePage: null,
  nextVaccinePage: null,
  totalVaccinePage: null,
  masterLists: [],
  villageCurrPage: 1,
  villageSteps: null,
  villageData: null,
  profiles: [],
  profileData: {},
  actionType: null,
  diseaseCurrRender: null,
  villageActiveStep: 0,
  villageIsInit: false,
  districtByState: [],
  districtByStateNxtPage: null,
  districtByStateCurrentPage: null,
  districtByStateTotalPage: null,
  talukaByDistrict: [],
  talukaByDistrictNxtPage: null,
  talukaByDistrictCurrentPage: null,
  talukaByDistrictTotalPage: null,
  villageByTaluka: [],
  villageByTalukaNxtPage: null,
  villageByTalukaCurrentPage: null,
  villageByTalukaTotalPage: null,
  store: [],
  storeParams: {},
  storeNxtPage: null,
  storeCurrentPage: null,
  storeTotalPage: null,
  storeActivePage: null,
  storeManagers: [],
  storeDetails: null,
  vender: [],
  vendorParams: {},
  venderNxtPage: null,
  venderCurrentPage: null,
  venderTotalPage: null,
  venderActivePage: null,
  venderDetails: null,
  products: [],
  stateParams: {},
  districtParams: {},
  talukParams: {},
  villageParams: {},
  catergoryList: [],
  selectedProduct: null,
  productVariants: []
};

const processSlice = createSlice({
  initialState,
  name: 'process',
  reducers: {
    resetProcess(state) {
      state.isLoading = false;
      state.success = null;
      state.error = null;
      state.states = [];
      state.stateNxtPage = null;
      state.stateCurrPage = null;
      state.stateTotalPage = null;
      state.districtNxtPage = null;
      state.districtCurrPage = null;
      state.districtTotalPage = null;
      state.talukaNxtPage = null;
      state.talukaCurrPage = null;
      state.talukaTotalPage = null;
      state.villageNxtPage = null;
      state.villageCurrentPage = null;
      state.villageTotalPage = null;
      state.district = [];
      state.taluka = [];
      state.village = [];
      state.languageList = [];
      state.masterList = [];
      state.diseaseList = [];
      state.diseaseGroup = [];
      state.diseaseType = [];
      state.currentTab = 0;
      state.masterId = null;
      state.languageParams = {};
      state.diseaseParams = {};
      state.activePage = null;
      state.stateNavDetails = null;
      state.vaccines = [];
      state.currVaccinePage = null;
      state.nextVaccinePage = null;
      state.totalVaccinePage = null;
      state.masterLists = [];
      state.villageCurrPage = 1;
      state.villageSteps = null;
      state.villageData = null;
      state.diseaseCurrRender = null;
      state.villageIsInit = false;
      state.districtNxtPage = null;
      state.districtCurrPage = null;
      state.districtTotalPage = null;
      state.talukaNxtPage = null;
      state.talukaCurrPage = null;
      state.talukaTotalPage = null;
      state.villageNxtPage = null;
      state.villageCurrentPage = null;
      state.villageTotalPage = null;
      state.districtByState = [];
      state.districtByStateNxtPage = null;
      state.districtByStateCurrentPage = null;
      state.districtByStateTotalPage = null;
      state.talukaByDistrict = [];
      state.talukaByDistrictNxtPage = null;
      state.talukaByDistrictCurrentPage = null;
      state.talukaByDistrictTotalPage = null;
      state.villageByTaluka = [];
      state.villageByTalukaNxtPage = null;
      state.villageByTalukaCurrentPage = null;
      state.villageByTalukaTotalPage = null;
      state.store = [];
      state.storeParams = {};
      state.storeNxtPage = null;
      state.storeCurrentPage = null;
      state.storeTotalPage = null;
      state.vender = [];
      state.vendorParams = {};
      state.venderNxtPage = null;
      state.venderCurrentPage = null;
      state.venderTotalPage = null;
      state.venderActivePage = null;
      state.venderDetails = null;
      state.products = [];
      state.stateParams = {};
      state.districtParams = {};
      state.talukParams = {};
      state.villageParams = {};
    },
    setClearProcessPages(state) {
      state.stateNxtPage = null;
      state.stateCurrPage = null;
      state.stateTotalPage = null;
      state.districtNxtPage = null;
      state.districtCurrPage = null;
      state.districtTotalPage = null;
      state.talukaNxtPage = null;
      state.talukaCurrPage = null;
      state.talukaTotalPage = null;
      state.villageNxtPage = null;
      state.villageCurrentPage = null;
      state.villageTotalPage = null;
      state.districtByStateNxtPage = null;
      state.districtByStateCurrentPage = null;
      state.districtByStateTotalPage = null;
      state.talukaByDistrictNxtPage = null;
      state.talukaByDistrictCurrentPage = null;
      state.talukaByDistrictTotalPage = null;
      state.villageByTalukaNxtPage = null;
      state.villageByTalukaCurrentPage = null;
      state.villageByTalukaTotalPage = null;
      state.venderCurrentPage = null;
      state.venderTotalPage = null;
      state.venderActivePage = null;
    },
    setVendorParams(state, action) {
      state.vendorParams = action.payload;
    },
    setStoreParams(state, action) {
      state.storeParams = action.payload;
    },
    setStateParams(state, action) {
      state.stateParams = action.payload;
    },
    setDistrictParams(state, action) {
      state.districtParams = action.payload;
    },
    setVillageParams(state, action) {
      state.villageParams = action.payload;
    },
    setTalukParams(state, action) {
      state.talukParams = action.payload;
    },
    setClearProcessStateDistTalVill(state) {
      state.district = [];
      state.taluka = [];
      state.village = [];
      state.states = [];
      state.districtByState = [];
      state.talukaByDistrict = [];
      state.villageByTaluka = [];
    },
    setVillageActiveStep(state, action) {
      state.villageActiveStep = action.payload;
    },
    setVillageIsInit(state, action) {
      state.villageIsInit = action.payload;
    },
    setDiseaseCurrRender(state, action) {
      state.diseaseCurrRender = action.payload;
    },
    updateStateNavDetails(state, action) {
      state.stateNavDetails = action.payload;
    },
    updateProcessCurrTab(state, action) {
      state.currentTab = action.payload;
    },
    updateLanguageParams(state, action) {
      state.languageParams = action.payload;
    },
    updateDiseaseParams(state, action) {
      state.diseaseParams = action.payload;
    },
    updateMasterId(state, action) {
      state.masterId = action.payload;
    },
    clearMasterData(state) {
      state.masterList = [];
      state.masterId = null;
    },
    clearStateList(state) {
      state.states = [];
    },
    updateVillageCurrPage(state, action) {
      state.villageCurrPage = action.payload;
    },
    setVillageData(state, action) {
      state.villageData = action.payload;
    },
    setVillageStep(state, action) {
      state.villageSteps = action.payload;
    },
    addStateRequest(state) {
      state.isLoading = true;
    },
    addStateSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.stateCurrPage = action.payload.data.current_page;
      state.stateNxtPage = action.payload.data.next_page;
      state.stateTotalPage = action.payload.data.total_page;
      state.states = action.payload.data.states;
    },
    addStateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getStateRequest(state) {
      state.isLoading = true;
    },
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.stateCurrPage = action.payload.data.current_page;
      state.stateNxtPage = action.payload.data.next_page;
      state.stateTotalPage = action.payload.data.total_page;
      state.error = null;
      state.states = action.payload.data.states;
    },
    getStateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.states = [];
      state.success = null;
    },
    updateStateRequest(state) {
      state.isLoading = true;
    },
    updateStateSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
    },
    updateStateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    addDistrictRequest(state) {
      state.isLoading = true;
    },
    addDistrictSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.districtCurrPage = action.payload.data.current_page;
      state.districtNxtPage = action.payload.data.next_page;
      state.districtTotalPage = action.payload.data.total_page;
      state.district = action.payload.data.district;
    },
    addDistrictFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getDistrictRequest(state) {
      state.isLoading = true;
    },
    getDistrictSuccess(state, action) {
      state.isLoading = false;
      state.districtCurrPage = action.payload.data.current_page;
      state.districtNxtPage = action.payload.data.next_page;
      state.districtTotalPage = action.payload.data.total_page;
      state.error = null;
      state.activeDistrictPage = null;
      state.district = action.payload.data.district;
    },
    getDistrictFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.district = [];
      state.success = null;
    },
    updateDistrictRequest(state) {
      state.isLoading = true;
    },
    updateDistrictSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.districtCurrPage = action.payload.data.current_page;
      state.districtNxtPage = action.payload.data.next_page;
      state.districtTotalPage = action.payload.data.total_page;
      state.error = null;
    },
    updateDistrictFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    addTalukRequest(state) {
      state.isLoading = true;
    },
    addTalukSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.talukaCurrPage = action.payload.data.current_page;
      state.talukaNxtPage = action.payload.data.next_page;
      state.talukaTotalPage = action.payload.data.total_page;
      state.taluka = action.payload.data.taluka;
    },
    addTalukFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getTalukRequest(state) {
      state.isLoading = true;
    },
    getTalukSuccess(state, action) {
      state.isLoading = false;
      state.talukaCurrPage = action.payload.data.current_page;
      state.talukaNxtPage = action.payload.data.next_page;
      state.talukaTotalPage = action.payload.data.total_page;
      state.error = null;
      state.activeTalukaPage = null;
      state.taluka = action.payload.data.taluka;
    },
    getTalukFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
      state.taluka = [];
    },
    updateTalukRequest(state) {
      state.isLoading = true;
    },
    updateTalukSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.talukaCurrPage = action.payload.data.current_page;
      state.talukaNxtPage = action.payload.data.next_page;
      state.talukaTotalPage = action.payload.data.total_page;
      state.error = null;
    },
    updateTalukFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    addVillageRequest(state) {
      state.isLoading = true;
    },
    addVillageSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.villageData = action.payload.data;
      state.villageSteps = action.payload.data.step;
    },
    addVillageFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    getVillageDetailsRequest(state) {
      state.isLoading = true;
    },
    getVillageDetailsSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.villageData = action.payload.data;
      state.villageSteps = action.payload.data.step;
    },
    getVillageDetailsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getVillageRequest(state) {
      state.isLoading = true;
    },
    getVillageSuccess(state, action) {
      state.isLoading = false;
      state.villageCurrentPage = action.payload.data.current_page;
      state.villageNxtPage = action.payload.data.next_page;
      state.villageTotalPage = action.payload.data.total_page;
      state.activeVillagePage = null;
      state.error = null;
      state.village = action.payload.data.village;
    },
    getVillageFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
      state.village = [];
    },

    updateVillageRequest(state) {
      state.isLoading = true;
    },
    updateVillageSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.villageData = { ...state.villageData, ...action.payload.data };
      state.villageSteps = action.payload.data.step;
    },
    updateVillageFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    updateVillageStatusRequest(state) {
      state.isLoading = true;
    },
    updateVillageStatusSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.villageCurrentPage = action.payload.data.current_page;
      state.villageNxtPage = action.payload.data.next_page;
      state.villageTotalPage = action.payload.data.total_page;
    },
    updateVillageStatusFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    getMasterListRequest(state) {
      state.isLoading = true;
    },
    getMasterListSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.masterList = action.payload.data;
    },
    getMasterListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getDiseaseGroupRequest(state) {
      state.isLoading = true;
    },
    getDiseaseGroupSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.diseaseGroup = action.payload.data;
    },
    getDiseaseGroupFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getDiseaseTypeRequest(state) {
      state.isLoading = true;
    },
    getDiseaseTypeSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.diseaseType = action.payload.data;
    },
    getDiseaseTypeFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    addMasterListRequest(state) {
      state.isLoading = true;
    },
    addMasterListSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.masterList = action.payload.data;
    },
    addMasterListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    updateMasterListRequest(state) {
      state.isLoading = true;
    },
    updateMasterListSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.masterList = action.payload.data;
    },
    updateMasterListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    getDiseaseRequest(state) {
      state.isLoading = true;
    },
    getDiseaseSuccess(state, action) {
      state.isLoading = false;
      state.diseaseList = action.payload.data.diseases;
      state.nextPage = action.payload.data.next_page;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
    },
    getDiseaseFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    addDiseaseRequest(state) {
      state.isLoading = true;
    },
    addDiseaseSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    addDiseaseFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    updateDiseaseRequest(state) {
      state.isLoading = true;
    },
    updateDiseaseSuccess(state, action) {
      state.isLoading = false;
      state.disease = action.payload.data.diseases;
      state.success = action.payload.status_code;
    },
    updateDiseaseFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    addVaccineRequest(state) {
      state.isLoading = true;
    },
    addVaccineSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.error = null;
      state.currVaccinePage = action.payload.data.current_page;
      state.nextVaccinePage = action.payload.data.next_page;
      state.totalVaccinePage = action.payload.data.total_page;
      state.vaccines = action.payload.data.vaccines;
    },
    addVaccineFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },
    getVaccineRequest(state) {
      state.isLoading = true;
    },
    getVaccineSuccess(state, action) {
      state.isLoading = false;
      state.currVaccinePage = action.payload.data.current_page;
      state.nextVaccinePage = action.payload.data.next_page;
      state.totalVaccinePage = action.payload.data.total_page;
      state.error = null;
      state.vaccines = action.payload.data.vaccines;
    },
    getVaccineFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
      state.vaccines = [];
    },
    updateVaccineRequest(state) {
      state.isLoading = true;
    },
    updateVaccineSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.currVaccinePage = action.payload.data.current_page;
      state.nextVaccinePage = action.payload.data.next_page;
      state.totalVaccinePage = action.payload.data.total_page;
      state.error = null;
      state.vaccines = action.payload.data.vaccines;
    },
    updateVaccineFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.success = null;
    },

    selectedState(state, action) {
      state.activePage = action.payload;
    },
    selectedDistrict(state, action) {
      state.activeDistrictPage = action.payload;
    },
    selectedTaluka(state, action) {
      state.activeTalukaPage = action.payload;
    },
    selectedVillage(state, action) {
      state.activeVillagePage = action.payload;
    },
    getMasterListWiseRequest(state) {
      state.isLoading = true;
    },
    getMasterListWiseSuccess(state, action) {
      state.isLoading = false;
      state.masterLists = action.payload.data;
    },
    getMasterListWiseFailure(state) {
      state.isLoading = false;
      state.masterLists = [];
    },
    resetManageProcessMsg(state) {
      state.error = null;
      state.success = null;
    },
    getProfilesRequest(state) {
      state.isLoading = true;
    },
    getProfilesSuccess(state, action) {
      state.isLoading = false;
      state.profiles = action.payload.data.profile;
      state.error = null;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.nextPage = action.payload.data.next_page;
    },
    getProfilesFailure(state, action) {
      state.isLoading = false;
      state.profiles = [];
      state.error = action.payload.status_code;
      state.currentPage = null;
      state.totalPage = null;
      state.nextPage = null;
    },
    addProfilingRequest(state) {
      state.isLoading = true;
    },
    addProfilingSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
      state.success = action.payload.status_code;
    },
    addProfilingFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    getProfileDetailsRequest(state) {
      state.isLoading = true;
    },
    getProfileDetailsSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
      state.success = action.payload.status_code;
    },
    getProfileDetailsFailure(state, action) {
      state.isLoading = false;
      state.profileData = {};
      state.error = action.payload.status_code;
    },
    setProfileActionType(state, action) {
      state.actionType = action.payload;
    },
    updateProfileDetailsRequest(state) {
      state.isLoading = true;
    },
    updateProfileDetailsSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
      state.success = action.payload.status_code;
      state.actionType = 'view';
    },
    updateProfileDetailsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    resetProfileData(state) {
      state.profileData = {};
      state.actionType = 'add';
    },
    getDistrictByStateRequest(state) {
      state.isLoading = true;
    },
    getDistrictByStateSuccess(state, action) {
      state.isLoading = false;
      state.districtByStateCurrentPage = action.payload.data.current_page;
      state.districtByStateNxtPage = action.payload.data.next_page;
      state.districtByStateTotalPage = action.payload.data.total_page;
      state.districtByState = action.payload.data.district;
    },
    getDistrictByStateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    getTalukaByDistrictRequest(state) {
      state.isLoading = true;
    },
    getTalukaByDistrictSuccess(state, action) {
      state.isLoading = false;
      state.talukaByDistrictCurrentPage = action.payload.data.current_page;
      state.talukaByDistrictNxtPage = action.payload.data.next_page;
      state.talukaByDistrictTotalPage = action.payload.data.total_page;
      state.talukaByDistrict = action.payload.data.taluka;
    },
    getTalukaByDistrictFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    getVillageByTalukaRequest(state) {
      state.isLoading = true;
    },
    getVillageByTalukaSuccess(state, action) {
      state.isLoading = false;
      state.villageByTalukaCurrentPage = action.payload.data.current_page;
      state.villageByTalukaNxtPage = action.payload.data.next_page;
      state.villageByTalukaTotalPage = action.payload.data.total_page;
      state.villageByTaluka = action.payload.data.village;
    },
    getVillageByTalukaFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    setStoreActivepage(state, action) {
      state.storeActivePage = action.payload;
    },
    getStoreRequest(state) {
      state.isLoading = true;
    },
    getStoreSuccess(state, action) {
      state.isLoading = false;
      state.store = action.payload.data.stores;
      state.storeCurrentPage = action.payload.data.current_page;
      state.storeNxtPage = action.payload.data.next_page;
      state.storeTotalPage = action.payload.data.total_page;
    },
    getStoreFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.store = [];
    },
    getStoreManagerRequest(state) {
      state.isLoading = true;
    },
    getStoreManagerSuccess(state, action) {
      state.isLoading = false;
      state.storeManagers = action.payload.data.employee;
    },
    getStoreManagerFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.storeManagers = [];
    },
    addStoreRequest(state) {
      state.isLoading = true;
    },
    addStoreSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    addStoreFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    updateStoreRequest(state) {
      state.isLoading = true;
    },
    updateStoreSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    updateStoreFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    getStoreDetailsRequest(state) {
      state.isLoading = true;
    },
    getStoreDetailsSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.storeDetails = action.payload.data;
    },
    getStoreDetailsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    setStoreDetails(state, action) {
      state.storeDetails = action.payload;
    },
    setVenderActivepage(state, action) {
      state.venderActivePage = action.payload;
    },
    getVenderRequest(state) {
      state.isLoading = true;
    },
    getVenderSuccess(state, action) {
      state.isLoading = false;
      state.vender = action.payload.data.vendors;
      state.venderCurrentPage = action.payload.data.current_page;
      state.venderNxtPage = action.payload.data.next_page;
      state.venderTotalPage = action.payload.data.total_page;
    },
    getVenderFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
      state.vender = [];
    },
    addVenderRequest(state) {
      state.isLoading = true;
    },
    addVenderSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    addVenderFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    updateVenderRequest(state) {
      state.isLoading = true;
    },
    updateVenderSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    updateVenderFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },

    setVenderDetails(state, action) {
      state.venderDetails = action.payload;
    },
    getProductRequest(state) {
      state.isLoading = true;
      state.productVariants = [];
    },
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.data.products;
      state.success = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.nextPage = action.payload.data.next_page;
    },
    getProductFailure(state, action) {
      state.isLoading = false;
      state.products = [];
      state.error = action.payload.status_code;
      state.currentPage = null;
      state.totalPage = null;
      state.nextPage = null;
    },
    addProductRequest(state) {
      state.isLoading = true;
    },
    addProductSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.products = action.payload.data.products;
    },
    addProductFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    updateProductRequest(state) {
      state.isLoading = true;
    },
    updateProductSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
      state.products = action.payload.data.products;
    },
    updateProductFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    getCategoryListRequest(state) {
      state.isLoading = true;
    },
    getCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.catergoryList = action.payload.category;
    },
    getCategoryListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    getProductVariantsRequest(state) {
      state.isLoading = true;
    },
    getProductVariantsSuccess(state, action) {
      state.isLoading = false;
      state.productVariants = action.payload.data.products;
      state.success = action.payload.status_code;
      state.currentPage = action.payload.data.current_page;
      state.totalPage = action.payload.data.total_page;
      state.nextPage = action.payload.data.next_page;
    },
    getProductVariantsFailure(state, action) {
      state.isLoading = false;
      state.productVariants = [];
      state.error = action.payload.status_code;
      state.currentPage = null;
      state.totalPage = null;
      state.nextPage = null;
    },
    addProductVariantRequest(state) {
      state.isLoading = true;
    },
    addProductVariantSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    addProductVariantFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    },
    updateProductVariantRequest(state) {
      state.isLoading = true;
    },
    updateProductVariantSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.status_code;
    },
    updateProductVariantFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.status_code;
    }
  }
});

export const {
  clearMasterData,
  updateStateNavDetails,
  clearStateList,
  addStateRequest,
  addStateFailure,
  addStateSuccess,
  getStateFailure,
  getStateRequest,
  getStateSuccess,
  updateStateFailure,
  updateStateRequest,
  updateStateSuccess,
  resetManageProcessMsg,
  addDistrictRequest,
  addDistrictFailure,
  addDistrictSuccess,
  getDistrictFailure,
  getDistrictRequest,
  getDistrictSuccess,
  updateDistrictFailure,
  updateDistrictRequest,
  updateDistrictSuccess,
  addTalukFailure,
  addTalukRequest,
  addTalukSuccess,
  getTalukFailure,
  getTalukRequest,
  getTalukSuccess,
  updateTalukFailure,
  updateTalukRequest,
  updateTalukSuccess,
  addVillageFailure,
  addVillageRequest,
  addVillageSuccess,
  getVillageFailure,
  getVillageRequest,
  getVillageSuccess,
  updateVillageFailure,
  updateVillageRequest,
  updateVillageSuccess,
  getMasterListFailure,
  getMasterListRequest,
  getMasterListSuccess,
  addMasterListFailure,
  addMasterListRequest,
  addMasterListSuccess,
  updateMasterListFailure,
  updateMasterListRequest,
  updateMasterListSuccess,
  getDiseaseFailure,
  getDiseaseRequest,
  getDiseaseSuccess,
  addDiseaseFailure,
  addDiseaseRequest,
  addDiseaseSuccess,
  updateDiseaseFailure,
  updateDiseaseRequest,
  updateDiseaseSuccess,
  getDiseaseGroupFailure,
  getDiseaseGroupRequest,
  getDiseaseGroupSuccess,
  getDiseaseTypeFailure,
  getDiseaseTypeRequest,
  getDiseaseTypeSuccess,
  updateProcessCurrTab,
  updateMasterId,
  updateLanguageParams,
  updateDiseaseParams,
  getVaccineFailure,
  getVaccineRequest,
  getVaccineSuccess,
  addVaccineFailure,
  addVaccineRequest,
  addVaccineSuccess,
  updateVaccineFailure,
  updateVaccineRequest,
  updateVaccineSuccess,
  selectedState,
  getMasterListWiseFailure,
  getMasterListWiseRequest,
  getMasterListWiseSuccess,
  updateVillageCurrPage,
  getVillageDetailsFailure,
  getVillageDetailsRequest,
  getVillageDetailsSuccess,
  setVillageData,
  setVillageStep,
  updateVillageStatusFailure,
  updateVillageStatusRequest,
  updateVillageStatusSuccess,
  getProfilesFailure,
  getProfilesRequest,
  getProfilesSuccess,
  addProfilingFailure,
  addProfilingRequest,
  addProfilingSuccess,
  getProfileDetailsFailure,
  getProfileDetailsRequest,
  getProfileDetailsSuccess,
  updateProfileDetailsFailure,
  updateProfileDetailsRequest,
  updateProfileDetailsSuccess,
  setProfileActionType,
  resetProcess,
  setDiseaseCurrRender,
  setVillageActiveStep,
  setVillageIsInit,
  resetProfileData,
  selectedDistrict,
  selectedTaluka,
  selectedVillage,
  setClearProcessPages,
  setClearProcessStateDistTalVill,
  getDistrictByStateFailure,
  getDistrictByStateRequest,
  getDistrictByStateSuccess,
  getTalukaByDistrictFailure,
  getTalukaByDistrictRequest,
  getTalukaByDistrictSuccess,
  getVillageByTalukaFailure,
  getVillageByTalukaRequest,
  getVillageByTalukaSuccess,
  setStoreActivepage,
  getStoreFailure,
  getStoreRequest,
  getStoreSuccess,
  getStoreManagerFailure,
  getStoreManagerRequest,
  getStoreManagerSuccess,
  addStoreFailure,
  addStoreRequest,
  addStoreSuccess,
  updateStoreFailure,
  updateStoreRequest,
  updateStoreSuccess,
  getStoreDetailsFailure,
  getStoreDetailsRequest,
  getStoreDetailsSuccess,
  setStoreDetails,
  setVenderActivepage,
  getVenderFailure,
  getVenderRequest,
  getVenderSuccess,
  addVenderFailure,
  addVenderRequest,
  addVenderSuccess,
  updateVenderFailure,
  updateVenderRequest,
  updateVenderSuccess,
  setVenderDetails,
  setStoreParams,
  setVendorParams,
  getProductRequest,
  getProductSuccess,
  getProductFailure,
  addProductRequest,
  addProductSuccess,
  addProductFailure,
  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,
  setStateParams,
  setDistrictParams,
  setTalukParams,
  setVillageParams,
  getCategoryListFailure,
  getCategoryListRequest,
  getCategoryListSuccess,
  setSelectedProduct,
  getProductVariantsFailure,
  getProductVariantsRequest,
  getProductVariantsSuccess,
  addProductVariantFailure,
  addProductVariantRequest,
  addProductVariantSuccess,
  updateProductVariantFailure,
  updateProductVariantRequest,
  updateProductVariantSuccess
} = processSlice.actions;

export default processSlice.reducer;
