import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import {
  DatePickers,
  EditButton,
  MultiImage,
  Screen,
  UIButton,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../ui-kits';
import { UIContainer } from '../../ui-kits/container';
import { getMasterListItems, getTitles, resetMessages, variantSchema } from '../../utils';
import { styles } from '../../pages/manage-process/manage-product-master/view-add-edit-product/style';
import { UIBackButton } from '../../ui-kits/back-button';
import { Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErrorMsg,
  getMasterLists,
  getSelectedProduct,
  getSuccessMsg,
  processLoading
} from '../../services/redux/selectors/manageProccess';
import { ModalButtons } from '../../components';
import { UIDeleteIcon } from '../../ui-kits/delete-icon-button';
import {
  addProductVariantRequest,
  resetManageProcessMsg,
  updateProductVariantRequest
} from '../../services/redux/slices';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getDetailsPg } from '../../services/redux/selectors';

export const AddVariants = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const listItems = useSelector(getMasterLists);
  const product = useSelector(getSelectedProduct);
  const success = useSelector(getSuccessMsg);
  const error = useSelector(getErrorMsg);
  const showBackButton = useSelector(getDetailsPg);
  const isLoading = useSelector(processLoading);

  const [actionType, setActionType] = useState(null);
  const [tempConditions, setTempConditions] = useState([]);
  const [cowBreed, setCowBreed] = useState([]);
  const [buffaloBreed, setBuffaloBreed] = useState([]);
  const [variety, setVariety] = useState([]);
  const [productMaterialGroup, setProductMaterialGroup] = useState(null);
  const [category, setCategory] = useState('');
  const [variantData, setVariantData] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [btnDisabled, setBtndisabled] = useState(true);

  useEffect(() => {
    if (product) {
      setProductMaterialGroup(product?.material_group?.id);
      setCategory(product?.category_enum?.toLowerCase());
    }
  }, [product]);

  useEffect(() => {
    if (!showBackButton) {
      handleBackButton();
    }
  }, [showBackButton]);

  useEffect(() => {
    const { actionType, data, isReadOnly } = location.state;
    setActionType(actionType);
    setVariantData(data);
    setIsReadOnly(isReadOnly);
  }, [location]);

  useEffect(() => {
    if (listItems) {
      setTempConditions(getMasterListItems(listItems, defaultValues.mListIds.temperatureCondition));
      setCowBreed(getMasterListItems(listItems, defaultValues.mListIds.cowBreed));
      setBuffaloBreed(getMasterListItems(listItems, defaultValues.mListIds.buffaloBreed));
      setVariety(getMasterListItems(listItems, defaultValues.mListIds.cowVariety));
    }
  }, [listItems]);

  const isRes = defaultValues.isResponsive;
  const { view, add, edit } = defaultValues.actionType;

  const {
    values,
    errors,
    touched,
    dirty,
    setValues,
    handleBlur,
    isValid,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      product_master_id: '',
      variant_name: '',
      description: '',
      image: [],
      additional_images: [],
      offer_scheme: '',
      min_order_qty: '',
      min_stock_qty: '',
      container_required: null,
      temp_conditions: { _id: '' },
      inspection_interval: '',
      sale_price: '',
      gross_weight: null,
      net_weight: '',
      discount: '',
      shipping: '',
      package_dimension: '',
      key_value: [{ key: '', value: '' }],
      // if catergory semen straw
      breed_id: '',
      variety_id: '',
      type: '',
      affiliation: '',
      semen_station: '',
      producer_first_name: '',
      producer_middle_name: '',
      producer_last_name: '',
      iso_certified: '',
      bull_number: '',
      bull_name: '',
      bull_image: [],
      bull_type: '',
      dob: null,
      inaph_id: '',
      dose: '',
      year_production: null,
      sire: '',
      dam_yield: '',
      fat_per: '',
      fat_kg: '',
      sir_dam_yield_kg: '',
      parents_avg: '',
      measurement: '',
      product_type: '',
      power: '',
      color: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: variantSchema(productMaterialGroup, category)
  });

  useEffect(() => {
    if (product) {
      setFieldValue('product_master_id', product.product_id);
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  useEffect(() => {
    if (variantData) {
      setValues({
        product_master_id: variantData.product_master_id || '',
        variant_name: variantData.variant_name || '',
        description: variantData.description || '',
        image: variantData.image ? variantData.image : [],
        additional_images: variantData.additional_images || [],
        offer_scheme: variantData.offer_scheme || '',
        min_order_qty: variantData.min_order_qty || '',
        min_stock_qty: variantData.min_stock_qty || '',
        container_required: variantData.container_required || false,
        temp_conditions: variantData.temp_conditions || '',
        inspection_interval: variantData.inspection_interval || '',
        sale_price: variantData.sale_price || '',
        gross_weight: variantData.gross_weight || null,
        net_weight: variantData.net_weight || '',
        discount: variantData.discount || '',
        shipping: variantData.shipping || '',
        package_dimension: variantData.package_dimension || '',
        key_value: Array.isArray(variantData.key_value)
          ? variantData.key_value.filter((item) => item.key && item.value).length > 0
            ? variantData.key_value.filter((item) => item.key && item.value)
            : [{ key: '', value: '' }]
          : [{ key: '', value: '' }],
        breed_id: variantData.breed_id?._id || '',
        variety_id: variantData.variety_id?._id || '',
        type: variantData.type || '',
        affiliation: variantData.affiliation || '',
        semen_station: variantData.semen_station || '',
        producer_first_name: variantData.producer_first_name || '',
        producer_middle_name: variantData.producer_middle_name || '',
        producer_last_name: variantData.producer_last_name || '',
        iso_certified: variantData.iso_certified || false,
        bull_number: variantData.bull_number || '',
        bull_name: variantData.bull_name || '',
        bull_image: variantData.bull_image ? [variantData.bull_image] : [],
        bull_type: variantData.bull_type || '',
        dob: variantData.dob ? dayjs(variantData.dob) : null,
        inaph_id: variantData.inaph_id || '',
        dose: variantData.dose || '',
        year_production: variantData.year_production ? dayjs(variantData.year_production) : null,
        sire: variantData.sire || '',
        dam_yield: variantData.dam_yield || '',
        fat_per: variantData.fat_per || '',
        fat_kg: variantData.fat_kg || '',
        sir_dam_yield_kg: variantData.sir_dam_yield_kg || '',
        parents_avg: variantData.parents_avg || '',
        measurement: variantData.measurement || '',
        product_type: variantData.product_type || '',
        power: variantData.power || '',
        color: variantData.color || ''
      });
    }
  }, [variantData]);

  useEffect(() => {
    if (success === 'S-10146') {
      toast.success(getSuccessMessage(success));
      setActionType(view);
      resetMessages(() => dispatch(resetManageProcessMsg()));
    }
    if (success === 'S-10145') {
      toast.success(getSuccessMessage(success));
      navigate(-1);
    }
    if (error) {
      toast.error(getErrorMessage(error));
      resetMessages(() => dispatch(resetManageProcessMsg()));
    }
  }, [success, error]);

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleEditButton = () => {
    setActionType(edit);
  };

  const handleImagesChange = (key) => (newImages) => {
    setFieldValue(key, newImages);
    setBtndisabled(false);
  };

  const handleDelete = (type, img) => {
    setFieldValue(type, img);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    const payload = { ...values };

    // Ensure `bull_number`, `inaph_id`, `power`, and `product_type` are always strings
    ['inaph_id', 'bull_number', 'power', 'product_type'].forEach((key) => {
      payload[key] = payload[key] ? String(payload[key]) : null;
    });

    // Ensure `temp_conditions` is an ObjectId
    if (
      payload.temp_conditions &&
      typeof payload.temp_conditions === 'object' &&
      payload.temp_conditions._id
    ) {
      payload.temp_conditions = payload.temp_conditions._id;
    } else if (typeof payload.temp_conditions === 'string') {
      try {
        const tempConditionsObj = JSON.parse(payload.temp_conditions);
        if (tempConditionsObj && tempConditionsObj._id) {
          payload.temp_conditions = tempConditionsObj._id;
        }
      } catch (e) {
        console.error('Invalid JSON in temp_conditions', e);
      }
    }

    ['image', 'bull_image', 'additional_images'].forEach((key) => {
      if (Array.isArray(values[key])) {
        const existingImages = values[key].filter((item) => typeof item === 'string'); // URLs
        const newFiles = values[key].filter((item) => item instanceof File); // New files

        if (existingImages.length > 0) {
          formData.append(`${key}_existing`, existingImages.join(',')); // Store URLs in a separate key
        }

        if (newFiles.length > 0) {
          newFiles.forEach((file) => formData.append(key, file));
        } else {
          delete payload[key];
        }
      } else if (typeof values[key] === 'string') {
        delete payload[key]; // If it's a single string, remove it
      }
    });

    if (Array.isArray(payload.key_value)) {
      payload.key_value = payload.key_value.filter(
        (item) => item.key.trim() !== '' && item.value.trim() !== ''
      );

      if (payload.key_value.length === 0) {
        delete payload.key_value;
      }
    }

    Object.keys(payload).forEach((key) => {
      if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
        delete payload[key];
      }
    });

    Object.keys(payload).forEach((key) => {
      let value = payload[key];

      if (
        ['power', 'inaph_id', 'bull_number', 'product_type'].includes(key) &&
        value !== null &&
        value !== undefined
      ) {
        value = String(value);
      }

      if (key === 'temp_conditions' && typeof value === 'object' && value._id) {
        value = value._id;
      }

      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });

    if (actionType === add) {
      dispatch(addProductVariantRequest(formData));
    }

    delete payload.product_master_id;
    if (actionType === edit) {
      if (variantData?._id) {
        formData.append('_id', variantData._id);
      }
      dispatch(updateProductVariantRequest(formData));
    }
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11744')}>
      {!isRes ? (
        <UIBackButton onClick={handleBackButton} backRoot={styles.backBtn} />
      ) : (
        <Box sx={styles.resEditCont}>
          {actionType === view && !isReadOnly && (
            <UIButton
              startIcon={<EditIcon />}
              style={styles.resEditBtn}
              onClick={handleEditButton}
            />
          )}
        </Box>
      )}
      <UIContainer styles={styles.mainCont}>
        {actionType === view && !isRes && !isReadOnly && (
          <UIButton
            title={getTitles('T-10084')}
            startIcon={<EditButton iconStyle={styles.editIcon} />}
            style={styles.editBtn}
            onClick={handleEditButton}
          />
        )}
        <Box sx={styles.variantContainer}>
          <Box sx={styles.subContainerVariant}>
            <Box sx={styles.variantField}>
              <UITextInputWithTitle
                name="variant_name"
                title={getTitles('T-11745')}
                placeHolder={getTitles('T-10384')}
                value={values.variant_name}
                error={touched.variant_name && errors.variant_name}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                allowOnlyAlphabets={true}
                disabled={actionType === view}
              />
              <UITextInputWithTitle
                name="product_id"
                title={getTitles('T-11437')}
                placeHolder={getTitles('T-10384')}
                value={product?.name}
                disabled={true}
              />
              <UITextInputWithTitle
                name="description"
                title={getTitles('T-10109')}
                value={values.description}
                placeHolder={getTitles('T-10096')}
                onChange={(val) => {
                  handleChange(val);
                }}
                allowOnlyAlphabets={true}
                rows={2}
                width={styles.textAreaWidth}
                disabled={actionType === view}
              />
            </Box>
            <Box sx={styles.variantImg}>
              <Box sx={styles.varImgStyle}>
                <MultiImage
                  title={getTitles('T-11747')}
                  images={values.image}
                  onImagesChange={handleImagesChange('image')}
                  prevImg={values.image}
                  disabled={actionType === view}
                  variant="D20"
                  imageToDelete={actionType !== add ? (val) => handleDelete('image', val) : null}
                  required={true}
                  accept={'image/*'}
                />
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="offer_scheme"
                title={getTitles('T-11439')}
                placeHolder={getTitles('T-10586')}
                value={values.offer_scheme}
                onChange={(val) => {
                  handleChange(val);
                }}
                disabled={actionType === view}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="min_order_qty"
                title={getTitles('T-11441')}
                placeHolder={getTitles('T-11050')}
                required={true}
                allowDecimal={true}
                value={values.min_order_qty}
                onChange={(val) => {
                  handleChange(val);
                }}
                onBlur={handleBlur}
                error={touched.min_order_qty && errors.min_order_qty}
                disabled={actionType === view}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="min_stock_qty"
                title={getTitles('T-11442')}
                placeHolder={getTitles('T-11050')}
                required={true}
                allowDecimal={true}
                value={values.min_stock_qty}
                onChange={(val) => {
                  handleChange(val);
                }}
                onBlur={handleBlur}
                error={touched.min_stock_qty && errors.min_stock_qty}
                disabled={actionType === view}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <UIRadioButtonWithTitle
                name="container_required"
                title={getTitles('T-11444')}
                required={true}
                checkedValue={values.container_required}
                onCheck={(val) => {
                  handleChange({ target: { name: 'container_required', value: val } });
                }}
                values={[
                  { title: getTitles('T-10705'), value: true },
                  { title: getTitles('T-10706'), value: false }
                ]}
                itemTitleStyle={styles.radioItemTitleStyle}
                error={touched.container_required && errors.container_required}
                disabled={actionType === view}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UISelect
                name="temp_conditions._id"
                title={getTitles('T-11443')}
                options={tempConditions}
                optionLabel="name"
                returnValue={'_id'}
                placeholder={getTitles('T-10350')}
                required={true}
                value={values.temp_conditions?._id}
                valueToShow={values.temp_conditions?.name}
                onChange={handleChange}
                error={touched.temp_conditions?._id && errors.temp_conditions?._id}
                onBlur={handleBlur}
                onlyView={actionType === view}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="inspection_interval"
                title={getTitles('T-11459')}
                placeHolder={getTitles('T-11268')}
                allowOnlyNumbers={true}
                value={values.inspection_interval}
                onChange={(val) => {
                  handleChange(val);
                }}
                onBlur={handleBlur}
                disabled={actionType === view}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="sale_price"
                title={getTitles('T-11525')}
                placeHolder={getTitles('T-10360')}
                error={touched.sale_price && errors.sale_price}
                value={values.sale_price}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={actionType === view}
                required
                allowDecimal
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="gross_weight"
                title={getTitles('T-11520')}
                placeHolder={getTitles('T-10360')}
                error={touched.gross_weight && errors.gross_weight}
                value={values.gross_weight}
                onChange={handleChange}
                onBlur={handleBlur}
                allowOnlyNumbers={true}
                readOnly={actionType === view}
                width={styles.secWidth}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="net_weight"
                title={getTitles('T-11521')}
                placeHolder={getTitles('T-10360')}
                error={touched.net_weight && errors.net_weight}
                value={values.net_weight}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={actionType === view}
                allowOnlyNumbers={true}
                width={styles.secWidth}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="discount"
                title={getTitles('T-11528')}
                placeHolder={getTitles('T-10360')}
                value={values.discount}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={actionType === view}
                allowDecimal
                required={true}
                error={touched.discount && errors.discount}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="shipping"
                title={getTitles('T-11530')}
                placeHolder={getTitles('T-10360')}
                error={touched.shipping && errors.shipping}
                value={values.shipping}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={actionType === view}
                required
                allowDecimal
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UITextInputWithTitle
                name="package_dimension"
                title={getTitles('T-11746')}
                placeHolder={getTitles('T-10725')}
                value={values.package_dimension}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={actionType === view}
              />
            </Grid>
          </Grid>
          {/*  semen straw */}
          {productMaterialGroup === defaultValues.inventoryMaterialGroupId.semenStraw && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UISelect
                    name="breed_id"
                    title={getTitles('T-11473')}
                    options={category?.toLowerCase() === 'cow' ? cowBreed : buffaloBreed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.breed_id}
                    optionLabel="name"
                    returnValue="_id"
                    error={touched.breed_id && errors.breed_id}
                    readOnly={actionType === view}
                    placeholder={getTitles('T-10350')}
                    width={styles.imgConWidth}
                    required
                  />
                </Grid>
                {category === 'cow' && (
                  <Grid item xs={12} md={4}>
                    <UISelect
                      name="variety_id"
                      title={getTitles('T-11474')}
                      options={variety}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      optionLabel="name"
                      returnValue="_id"
                      value={values.variety_id}
                      error={touched.variety_id && errors.variety_id}
                      readOnly={actionType === view}
                      placeholder={getTitles('T-10350')}
                      width={styles.imgConWidth}
                      required
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UISelect
                    name="type"
                    title={getTitles('T-10088')}
                    options={defaultValues.semenType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    error={touched.type && errors.type}
                    readOnly={actionType === view}
                    placeholder={getTitles('T-10350')}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UISelect
                    name="affiliation"
                    title={getTitles('T-11499')}
                    options={defaultValues.affiliation}
                    onChange={handleChange}
                    value={values.affiliation}
                    readOnly={actionType === view}
                    placeholder={getTitles('T-10350')}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UISelect
                    title={getTitles('T-11500')}
                    options={defaultValues.accreditionType}
                    name="semen_station"
                    onChange={handleChange}
                    value={values.semen_station}
                    readOnly={actionType === view}
                    placeholder={getTitles('T-10350')}
                    width={styles.tertiaryWidth}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="producer_first_name"
                    title={getTitles('T-11502')}
                    placeHolder={getTitles('T-10047')}
                    error={touched.producer_first_name && errors.producer_first_name}
                    value={values.producer_first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    required
                    allowOnlyAlphabets={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="producer_middle_name"
                    title={getTitles('T-10337')}
                    placeHolder={getTitles('T-10338')}
                    value={values.producer_middle_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    allowOnlyAlphabets={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="producer_last_name"
                    title={getTitles('T-10042')}
                    placeHolder={getTitles('T-10048')}
                    error={touched.producer_last_name && errors.producer_last_name}
                    value={values.producer_last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.tertiaryWidth}
                    allowOnlyAlphabets={true}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UIRadioButtonWithTitle
                    name="iso_certified"
                    title={getTitles('T-11504')}
                    required={true}
                    checkedValue={values.iso_certified}
                    onCheck={(val) => {
                      handleChange({ target: { name: 'iso_certified', value: val } });
                    }}
                    values={[
                      { title: getTitles('T-10705'), value: true },
                      { title: getTitles('T-10706'), value: false }
                    ]}
                    itemTitleStyle={styles.radioItemTitleStyle}
                    error={touched.iso_certified && errors.iso_certified}
                    disabled={actionType === view}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="bull_number"
                    title={getTitles('T-11505')}
                    placeHolder={getTitles('T-10586')}
                    error={touched.bull_number && errors.bull_number}
                    value={values.bull_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.imgConWidth}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="bull_name"
                    title={getTitles('T-11507')}
                    placeHolder={getTitles('T-10384')}
                    error={touched.bull_name && errors.bull_name}
                    value={values.bull_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    allowOnlyAlphabets={true}
                    readOnly={actionType === view}
                    width={styles.imgConWidth}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} marginY={1}>
                  <MultiImage
                    title={getTitles('T-11506')}
                    images={values.bull_image}
                    onImagesChange={handleImagesChange('bull_image')}
                    prevImg={values.bull_image}
                    disabled={actionType === view}
                    variant="D20"
                    imageToDelete={
                      actionType !== add ? (val) => handleDelete('bull_image', val) : null
                    }
                    containerStyle={styles.imageCont}
                    showAdd={false}
                    showBubble={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UISelect
                    title={getTitles('T-11478')}
                    options={defaultValues.bullType}
                    name="bull_type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bull_type}
                    error={touched.bull_type && errors.bull_type}
                    readOnly={actionType === view}
                    placeholder={getTitles('T-10350')}
                    width={styles.imgConWidth}
                    required
                  />
                  <DatePickers
                    dateValue={values.dob}
                    onDateChange={(val) => handleChange({ target: { value: val, name: 'dob' } })}
                    title={getTitles('T-10344')}
                    width={styles.imgConWidth}
                    error={touched.dob && errors.dob}
                    onOpen={() => setFieldTouched('dob', true)}
                    required
                    disabled={actionType === view}
                    disableFuture
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="inaph_id"
                    title={getTitles('T-11508')}
                    placeHolder={getTitles('T-11538')}
                    error={touched.inaph_id && errors.inaph_id}
                    value={values.inaph_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.imgConSecWidth}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="dose"
                    title={getTitles('T-11509')}
                    placeHolder={getTitles('T-10360')}
                    error={touched.dose && errors.dose}
                    value={values.dose}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    required
                    allowOnlyNumbers
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePickers
                    dateValue={values.year_production}
                    onDateChange={(val) =>
                      handleChange({ target: { value: val, name: 'year_production' } })
                    }
                    title={getTitles('T-11479')}
                    width={styles.width}
                    required={true}
                    error={touched.year_production && errors.year_production}
                    onOpen={() => setFieldTouched('year_production', true)}
                    disableFuture
                    disabled={actionType === view}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="sire"
                    title={getTitles('T-11510')}
                    placeHolder={getTitles('T-10586')}
                    value={values.sire}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="dam_yield"
                    title={getTitles('T-11511')}
                    placeHolder={getTitles('T-10360')}
                    error={touched.dam_yield && errors.dam_yield}
                    value={values.dam_yield}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    required
                    allowOnlyNumbers
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="fat_per"
                    title={getTitles('T-11512')}
                    placeHolder={getTitles('T-11536')}
                    error={touched.fat_per && errors.fat_per}
                    value={values.fat_per}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    required
                    allowDecimal
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="fat_kg"
                    title={getTitles('T-11513')}
                    placeHolder={getTitles('T-10360')}
                    error={touched.fat_kg && errors.fat_kg}
                    value={values.fat_kg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.width}
                    required
                    allowOnlyNumbers
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="sir_dam_yield_kg"
                    title={getTitles('T-11514')}
                    placeHolder={getTitles('T-10360')}
                    value={values.sir_dam_yield_kg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.tertiaryWidth}
                    allowOnlyNumbers
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UITextInputWithTitle
                    name="parents_avg"
                    title={getTitles('T-11515')}
                    placeHolder={getTitles('T-10360')}
                    value={values.parents_avg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={actionType === view}
                    width={styles.tertiaryWidth}
                    allowOnlyNumbers
                  />
                </Grid>
              </Grid>
            </>
          )}
          {/* medicines */}
          {productMaterialGroup === defaultValues.inventoryMaterialGroupId.medicines && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <UITextInputWithTitle
                  name="measurement"
                  title={getTitles('T-11748')}
                  placeHolder={getTitles('T-10360')}
                  value={values.measurement}
                  error={touched.measurement && errors.measurement}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={actionType === view}
                  required
                />
              </Grid>
            </Grid>
          )}
          {/* equipment */}
          {productMaterialGroup === defaultValues.inventoryMaterialGroupId.equipment && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <UISelect
                  name="product_type"
                  title={getTitles('T-11472')}
                  options={defaultValues.equipmentType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.product_type}
                  error={touched.product_type && errors.product_type}
                  readOnly={actionType === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.imgConWidth}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UITextInputWithTitle
                  name="power"
                  title={getTitles('T-11532')}
                  placeHolder={getTitles('T-10586')}
                  value={values.power}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.power && errors.power}
                  readOnly={actionType === view}
                  width={styles.width}
                  required
                />
              </Grid>
            </Grid>
          )}
          {!(
            productMaterialGroup === defaultValues.inventoryMaterialGroupId.equipment ||
            productMaterialGroup === defaultValues.inventoryMaterialGroupId.medicines ||
            productMaterialGroup === defaultValues.inventoryMaterialGroupId.semenStraw
          ) && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <UITextInputWithTitle
                  name="color"
                  title={getTitles('T-10178')}
                  placeHolder={getTitles('T-10586')}
                  value={values.color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.color && errors.color}
                  readOnly={actionType === view}
                  width={styles.width}
                  allowOnlyAlphabets={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UITextInputWithTitle
                  name="power"
                  title={getTitles('T-11532')}
                  placeHolder={getTitles('T-10586')}
                  value={values.power}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.power && errors.power}
                  readOnly={actionType === view}
                  width={styles.width}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UITextInputWithTitle
                  name="product_type"
                  title={getTitles('T-11472')}
                  placeHolder={getTitles('T-10384')}
                  value={values.product_type}
                  error={touched.product_type && errors.product_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width={styles.imgConWidth}
                  readOnly={actionType === view}
                  required
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            {/* Image Section */}
            <Grid item xs={12} md={4} marginTop={1}>
              <MultiImage
                title={getTitles('T-11749')}
                images={values.additional_images}
                onImagesChange={handleImagesChange('additional_images')}
                prevImg={values.additional_images}
                disabled={actionType === view}
                variant="D20"
                imageToDelete={
                  actionType !== add ? (val) => handleDelete('additional_images', val) : null
                }
              />
            </Grid>

            {/* Inputs Section */}
            <Grid item xs={12} md={8} marginTop={1}>
              {values?.key_value?.map((item, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={5}>
                    <UITextInputWithTitle
                      name={`key_value[${index}].key`}
                      title={getTitles('T-11750')}
                      placeHolder={getTitles('T-10384')}
                      value={item.key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={actionType === view}
                      error={touched.key_value?.[index]?.key && errors.key_value?.[index]?.key}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <UITextInputWithTitle
                      name={`key_value[${index}].value`}
                      title={getTitles('T-11751')}
                      placeHolder={getTitles('T-10384')}
                      value={item.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={actionType === view}
                      error={touched.key_value?.[index]?.value && errors.key_value?.[index]?.value}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="center" alignItems="end">
                    {values.key_value.length > 1 && (
                      <UIDeleteIcon
                        onClick={() => {
                          const newKeyValue = [...values.key_value];
                          newKeyValue.splice(index, 1);
                          setFieldValue('key_value', newKeyValue);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}

              <UISaveAndAdd
                onAddAnother={() => {
                  setFieldValue('key_value', [...values.key_value, { key: '', value: '' }]);
                }}
                disabled={
                  values.key_value.length > 0 &&
                  (values.key_value[values.key_value.length - 1].key?.trim() === '' ||
                    values.key_value[values.key_value.length - 1].value?.trim() === '')
                }
              />
            </Grid>
          </Grid>
        </Box>
        {actionType !== view && (
          <Grid container sx={styles.gridCont} marginTop={3}>
            <Grid item xs={12} md={4} xl={3}>
              <ModalButtons
                btnPositions={'flex-start'}
                containerStyle={styles.buttonCont}
                primaryBtnTitle={
                  actionType === add
                    ? getTitles('T-10394')
                    : actionType === edit && getTitles('T-10046')
                }
                onPrimaryBtnClick={actionType !== view && handleSubmit}
                disabled={!isValid || !dirty || btnDisabled}
              />
            </Grid>
          </Grid>
        )}
      </UIContainer>
    </Screen>
  );
};
