import axios from 'axios';
import { API } from '../../constants/api';
import { defaultValues, KEYS, SCREEN_PATH } from '../../constants';
import { userLogout } from '../redux/slices';
import { store } from '../redux/store';

const defaultHeader = {
  'Content-type': 'application/json'
};

const multipartHeaders = {
  'Content-Type': 'multipart/form-data'
};

const getToken = () => localStorage.getItem(KEYS.ACCESS_KEY);

const authHeader = () => getToken() && `Bearer ${getToken()}`;

const createAxiosInstance = async (info) => {
  try {
    const { url, method, headers, data, params } = info;
    const axiosInstance = axios.create({
      baseURL: API.baseURL[API.currentEnv],
      headers
    });
    return await axiosInstance({ url, method, headers, data, params, timeout: 120000 });
  } catch (error) {
    if (error.response) {
      if (error.response.data?.status_code === 'E-10011') {
        if (!store.getState().auth.eCommerce) {
          store.dispatch(userLogout());
          store.dispatch({ type: defaultValues.appLogicalTexts.userLogout });
          window.location.href = SCREEN_PATH.HOME;
        }
      }
      return error.response;
    }

    if (error.code === 'ERR_NETWORK') {
      return { data: { status_code: 'E-10197' } };
    }
    if (error.code === 'ECONNABORTED') {
      return { data: { status_code: 'E-10198' } };
    }
    return { data: { status_code: 'E-10001' } };
  }
};

export function loginAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.login_request,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function verifyLoginAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.verify_login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function resendOtpAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.resend_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function getProfileAPI() {
  return createAxiosInstance({
    url: API.auth_urls.profile,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export function editProfileAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.profile,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addStateAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.states,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getStateAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.states,
    method: 'GET',
    params: values,
    headers: { Authorization: authHeader() }
  });
}

export function updateStateAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.states,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addDistrictAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.district,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getDistrictAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.district,
    method: 'GET',
    params: values,
    headers: { Authorization: authHeader() }
  });
}

export function updateDistrictAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.district,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addTalukAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.taluk,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getTalukAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.taluk,
    method: 'GET',
    params: values,
    headers: { Authorization: authHeader() }
  });
}

export function updateTalukAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.taluk,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addVillageAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village_details,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getVillageAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getVillageDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateVillageAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village_details,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function updateVillageStatusAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getLanguagesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.languages,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addLanguagesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.languages,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateLanguagesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.languages,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getMasterListAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.masterlist,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addMasterListAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.masterlist,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateMasterListAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.masterlist,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getDiseaseAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.disease,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addDiseaseAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.disease,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateDiseaseAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.disease,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export async function changeProPicApi(data) {
  return createAxiosInstance({
    url: API.auth_urls.profile_picture,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: data
  });
}

export async function deleteProPicApi() {
  return createAxiosInstance({
    url: API.auth_urls.profile_picture,
    method: 'DELETE',
    headers: { Authorization: authHeader() }
  });
}

export function addVaccineAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.vaccine,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getVaccineAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.vaccine,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateVaccineAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.vaccine,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getEmployeeListAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.employeeList,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addEmployeeAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.employees,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateEmployeeAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.employees,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getEmployeeAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.employees,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getEmployeeNamesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_employees,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function uploadDocsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.upload_employee_docs,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}

export function deleteEmployeeDocsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.upload_employee_docs,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getVetsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.vets,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getUnassignAiApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.unassign_ai,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addAiToVetApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_ai_to_vet,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateAssignedAiApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.update_assigned_ai,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateVetStatusApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.vets,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getAIWorkersApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ai_worker,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addVetToAiApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_vet_to_ai,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateAssignedVetApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_vet_to_ai,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateAIWorkerStatusApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ai_worker,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getSocietyAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.society,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addSocietyAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.society,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function getUnassignedCattleApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.unassigned_cattles,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addCattleToAiApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_cattles,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateSocietyAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.society,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function updateAssignedCattleApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_cattles,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getSocietyDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.society_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getReligionsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.religions,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getMasterListsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.mTypes,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addFarmerApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.farmers,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getFarmerApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.farmers,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function uploadFarmerDocsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.upload_farmer_doc,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}

export function deleteFarmerDocsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.upload_farmer_doc,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateFarmerAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.farmers,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function farmerDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.farmer_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getUnAssignedVetsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.unassignedVet,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addLaboratoryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.laboratory,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}
export function getCattlesApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getReportsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.reports,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addCattleAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getLaboratoryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.laboratory,
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getCattleDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getLaboratoryDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.laboratoryDetails,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateLaboratoryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.laboratory,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}
export function uploadCattlePhotosAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle_photos,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}

export function deleteCattlePhotoAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle_photos,
    method: 'DELETE',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateCattleAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addInsuranceAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.insurance,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getInsuranceAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.insurance,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateInsuranceAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.insurance,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getInsuranceFarmerAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.insurance_farmer_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getInsuranceCattleAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.insurance_cattle_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addGovtOfficialAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.govt_official,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getGovtOfficialAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.govt_official,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getGovtOfficialDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.govt_official_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateGovtOfficialAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.govt_official,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getAsscociatedFarmerAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.associated_farmer,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getUnassignedLabApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_suggestion,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function assignLabToUserApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.assign_lab_to_user,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getProfilesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.profiling,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addProfilesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.profiling,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getProfileDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.profile_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateProfilesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.profiling,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function callAgentMyTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_my_tasks,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function callAgentAllTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.all_tasks_call_center,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function callAgentAllCallHistoryApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.all_call_history,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function callCenterTaskDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_task_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function callCenterUsersApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_users_suggestions,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function callCenterUserInfoApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_user_info,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function addTaskCallCenterApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.tasks_call_center,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function updateTaskCallCenterApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.tasks_call_center,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function callerTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.caller_tasks,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function callerCallHistoryApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.caller_call_history,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function addNoteApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.note,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function cattleOrEmployeeApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattleOrEmployee,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function callerDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.caller_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function cattleTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.cattle_tasks,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getDistrictByStateAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.district_by_state,
    method: 'GET',
    params: { ...values },
    headers: { Authorization: authHeader() }
  });
}

export function getVillageByTalukaAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village_by_taluka,
    method: 'GET',
    params: { ...values, limit: 60 },
    headers: { Authorization: authHeader() }
  });
}
export function getTalukaByDistrictAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.taluka_by_district,
    method: 'GET',
    params: values,
    headers: { Authorization: authHeader() }
  });
}

export function getStoreApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_store,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getEmployeeByPermissionApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.employees_by_permissions,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addStoreApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_store,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function updateStoreApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_store,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getStoreDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_store_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getGovtOfficialDashboardAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.govt_dashboard,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getVenderApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.vender,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addVenderApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.vender,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function updateVenderApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.vender,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.process_products,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...values, limit: 100 }
  });
}

export function addProductAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.process_products,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateProductAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.process_products,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getInventoryProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_products,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addInventoryProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_add_edit_products,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateInventoryProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_add_edit_products,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getMyTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.my_tasks,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getInventoryProductDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_product_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addMytasksAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.task,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function getBannersApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.banners,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getOutwardProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_outward_products,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getEcommerceProductsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_products,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function addInventoryOutwardProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_add_outward_products,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateMytasksAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.task,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}
export function addInventoryAdjustmentProductsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_add_adjustment_products,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getAssociatedFarmersTasksApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.associated_farmer_tasks,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getOutwardProductsHistoryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.inventory_outward_products_history,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getCommonTaskDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.task_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getEcommerceProductDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecom_product_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getLabFarmersApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmers,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getLabFarmersCattlesApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmer_cattles,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getLabFarmersCattleDetailsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmer_cattle_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getLabFarmersCattleReportApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmer_cattle_Reports,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function addLabFarmersCattleReportApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmer_cattle_Reports,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateLabFarmersCattleReportApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.lab_farmer_cattle_Reports,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getEcommerceCartItemsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_cart_items,
    method: 'GET',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function addEcommerceCartItemsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_cart_items,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function updateEcommerceCartItemsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_cart_items,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getNotificationsApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.notifications,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function callCenterAgentApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_agents,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function ecomLoginAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.ecom_login_request,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function ecomResendOtpAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.ecom_resend_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function ecomVerifyLoginAPI(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.ecom_verify_login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function getEcomMyOrdersListAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_my_orders,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateCallCenterAgentApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.call_center_agents,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function getProductsByUserApi(values) {
  return createAxiosInstance({
    url: API.auth_urls.products_by_user,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}
export function getEcomManageOrdersAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_manage_orders,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getEcomManageOrderDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_manage_order_details,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function updateEcomManageOrderDetailsAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.ecommerce_update_order_details,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function sendQueryAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.send_query,
    method: 'POST',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function markNotificationReadAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.notification_read,
    method: 'PATCH',
    headers: { Authorization: authHeader() },
    data: values
  });
}

export function InitiatePaymentAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.payment,
    method: 'POST',
    data: values
  });
}

export function getNoAuthStateAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.states,
    method: 'GET',
    params: { ...values }
  });
}

export function getNoAuthDistrictAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.district,
    method: 'GET',
    params: { ...values }
  });
}

export function getNoAuthVillageAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.village,
    method: 'GET',
    params: { ...values, limit: 60 }
  });
}
export function getNoAuthTalukaAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.taluk,
    method: 'GET',
    params: values
  });
}

export function paymentStatusAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.payment_status,
    method: 'GET',
    params: values
  });
}

export function getBatchesAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.batches,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: values
  });
}

export function getCatergoriesAPI(id) {
  return createAxiosInstance({
    url: `${API.auth_urls.categoreis}/${id}`,
    method: 'GET',
    headers: { Authorization: authHeader() }
  });
}

export function getProductVariantssAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.getVariantLIst,
    method: 'GET',
    headers: { Authorization: authHeader() },
    params: { ...values, limit: 100 }
  });
}

export function addProductVariantAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.productVariant,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}

export function updateProductVariantAPI(values) {
  return createAxiosInstance({
    url: API.auth_urls.productVariant,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: authHeader() },
    data: values
  });
}
