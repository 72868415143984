export * from './home';
export * from './loginPage';
export * from './dashboard';
export * from './profilepage';
export * from './manageState';
export * from './manage-process';
export * from './manage-process/manage-disease';
export * from './manage-process/manage-vendor';
export * from './manage-process/manage-product-master';
export * from './manage-employee';
export * from './call-center';
export * from './e-com-order-management';

export * from './farmers/manage-farmer';
export * from './farmers/register-farmer';
export * from './manage-vet';
export * from './manage-ai-worker';
export * from './manage-cattle/view-cattles';
export * from './manage-cattle/cattle-registration';
export * from './manage-society';
export * from './manage-laboratory';
export * from './manage-govt-official';

export * from './privacy-policy';
export * from './shipping-policy';
export * from './disclaimer';
export * from './manage-village-for-village-manager';
export * from './manage-inventory';
export * from './manage-tasks';
export * from './e-commerce';
export * from './lab-farmer';

export * from './terms-conditions';
export * from './about-us';
export * from './contact-us';
export * from './notification';

export * from './manage-variants';
