import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  featuredCurrRender: 0,
  recentCurrRender: 0,
  productListTabCurrRender: 0,
  productListTabExpand: false,
  banners: [],
  featuredProducts: [],
  recentProducts: [],
  activityProducts: [],
  featuredCurrMateriaGrp: 1,
  recentCurrMateriaGrp: 1,
  activityRecentCurrMateriaGrp: 1,
  adverticement: [],
  bestDeals: [],
  hot: [],
  bestSeller: [],
  newArrival: [],
  activityProductsCurrPage: null,
  activityProductsNextPage: null,
  activityProductsTotalPage: null,
  cartItems: null,
  cartOpen: false,
  totalProduct: 0,
  activityFilter: null,
  searchText: null,
  productDetails: null,
  myOrders: [],
  myOrderCurrentPage: null,
  myOrderNextPage: null,
  myOrderTotalPage: null,
  ecomOrders: [],
  ecomOrderDetails: {},
  ecomOrdersCurrPage: null,
  ecomOrdersNextPage: null,
  ecomOrdersTotalPage: null,
  ecomManageOrderActionType: null,
  shippingAddress: null,
  callBackUrl: null,
  batches: []
};

const eCommerceSlice = createSlice({
  initialState,
  name: 'eCommerce',
  reducers: {
    resetECommerce(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.featuredCurrRender = 0;
      state.recentCurrRender = 0;
      state.productListTabCurrRender = 0;
      state.featuredExpand = false;
      state.recentExpand = false;
      state.productListTabExpand = false;
      state.banners = [];
      state.featuredProducts = [];
      state.recentProducts = [];
      state.featuredCurrMateriaGrp = 1;
      state.recentCurrMateriaGrp = 1;
      state.activityRecentCurrMateriaGrp = 1;
      state.adverticement = [];
      state.bestDeals = [];
      state.hot = [];
      state.bestSeller = [];
      state.newArrival = [];
      state.activityProductsCurrPage = null;
      state.activityProductsNextPage = null;
      state.activityProductsTotalPage = null;
      state.cartItems = null;
      state.cartOpen = false;
      state.totalProduct = 0;
      state.activityFilter = null;
      state.searchText = null;
      state.myOrders = [];
      state.myOrderCurrentPage = null;
      state.myOrderNextPage = null;
      state.myOrderTotalPage = null;
      state.ecomOrders = [];
      state.ecomOrderDetails = {};
      state.ecomOrdersCurrPage = null;
      state.ecomOrdersNextPage = null;
      state.ecomOrdersTotalPage = null;
      state.ecomManageOrderActionType = null;
    },
    resetECommerceMsg(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
    },
    setECommerceFeaturedCurrRender(state, action) {
      state.featuredCurrRender = action.payload;
    },
    setEcommerceRecentCurrRender(state, action) {
      state.recentCurrRender = action.payload;
    },
    setEcommerceProducListRecentExpand(state, action) {
      state.productListTabExpand = action.payload;
    },
    setEcommerceProducListTabCurrRender(state, action) {
      state.productListTabCurrRender = action.payload;
    },
    setEcommerceFeaturedMaterialGrp(state, action) {
      state.featuredCurrMateriaGrp = action.payload;
    },
    setEcommerceRecentMaterialGrp(state, action) {
      state.recentCurrMateriaGrp = action.payload;
    },
    setEcommerceActivityRecentMaterialGrp(state, action) {
      state.activityRecentCurrMateriaGrp = action.payload;
    },
    setCartItems(state, action) {
      state.cartItems = action.payload;
    },

    setCartOpen(state, action) {
      state.cartOpen = action.payload;
    },

    setActivityFilter(state, action) {
      state.activityFilter = action.payload;
    },
    setActivitySearchText(state, action) {
      state.searchText = action.payload;
    },
    setEcomShippingAddress(state, action) {
      state.shippingAddress = action.payload;
    },
    setManageOrderActionType(state, action) {
      state.ecomManageOrderActionType = action.payload;
    },
    getBannersRequest(state) {
      state.isLoading = true;
    },
    getBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload.data.banners;
    },
    getBannersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getFeaturedProductsRequest(state) {
      state.isLoading = true;
    },
    getFeaturedProductsSuccess(state, action) {
      state.isLoading = false;
      state.featuredProducts = action.payload.data.products;
    },
    getFeaturedProductsFailure(state) {
      state.isLoading = false;
      state.featuredProducts = [];
    },
    getRecentProductsRequest(state) {
      state.isLoading = true;
    },
    getRecentProductsSuccess(state, action) {
      state.isLoading = false;
      state.recentProducts = action.payload.data.products;
    },
    getRecentProductsFailure(state) {
      state.isLoading = false;
      state.recentProducts = [];
    },
    getAdverticementRequest(state) {
      state.isLoading = true;
    },
    getAdverticementSuccess(state, action) {
      state.isLoading = false;
      state.adverticement = action.payload.data.products;
    },
    getAdverticementFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getBestDealsRequest(state) {
      state.isLoading = true;
    },
    getBestDealsSuccess(state, action) {
      state.isLoading = false;
      state.bestDeals = action.payload.data.products;
    },
    getBestDealsFailure(state) {
      state.isLoading = false;
      state.bestDeals = [];
    },
    getHotRequest(state) {
      state.isLoading = true;
    },
    getHotSuccess(state, action) {
      state.isLoading = false;
      state.hot = action.payload.data.products;
    },
    getHotFailure(state) {
      state.isLoading = false;
      state.hot = [];
    },
    getBestSellerRequest(state) {
      state.isLoading = true;
    },
    getBestSellerSuccess(state, action) {
      state.isLoading = false;
      state.bestSeller = action.payload.data.products;
    },
    getBestSellerFailure(state) {
      state.isLoading = false;
      state.bestSeller = [];
    },
    getNewArrivalRequest(state) {
      state.isLoading = true;
    },
    getNewArrivalSuccess(state, action) {
      state.isLoading = false;
      state.newArrival = action.payload.data.products;
    },
    getNewArrivalFailure(state) {
      state.isLoading = false;
      state.newArrival = [];
    },
    getActivityProductsRequest(state) {
      state.isLoading = true;
    },
    getActivityProductsSuccess(state, action) {
      state.isLoading = false;
      state.activityProducts = action.payload.data.products;
      state.activityProductsCurrPage = action.payload.data.current_page;
      state.activityProductsNextPage = action.payload.data.next_page;
      state.activityProductsTotalPage = action.payload.data.total_page;
      state.totalProduct = action.payload.data.total_products;
    },
    getActivityProductsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.activityProducts = [];
      state.totalProduct = 0;
    },
    getEcomProductDetailsRequest(state) {
      state.isLoading = true;
    },
    getEcomProductDetailsSuccess(state, action) {
      state.isLoading = false;
      state.productDetails = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getEcomProductDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCartItemsRequest(state) {
      state.isLoading = true;
    },
    getCartItemsSuccess(state, action) {
      state.isLoading = false;
      const result = action.payload.data.products?.reduce((acc, curr) => {
        acc[curr.product_id] = curr;
        return acc;
      }, {});
      state.cartItems = (Object.keys(result || {}).length > 0 && result) || null;
      state.successMessage = action.payload.status_code;
    },
    getCartItemsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.cartItems = action.payload.status_code === 'E-10278' ? null : state.cartItems;
    },
    addCartItemsRequest(state) {
      state.isLoading = true;
    },
    addCartItemsSuccess(state, action) {
      state.isLoading = false;
      const result = action.payload.data.products?.reduce((acc, curr) => {
        acc[curr.product_id] = curr;
        return acc;
      }, {});
      state.cartItems = (Object.keys(result || {}).length > 0 && result) || null;
      state.successMessage = action.payload.status_code;
    },
    addCartItemsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateCartItemsRequest(state) {
      state.isLoading = true;
    },
    updateCartItemsSuccess(state, action) {
      state.isLoading = false;
      const result = action.payload.data.products?.reduce((acc, curr) => {
        acc[curr.product_id] = curr;
        return acc;
      }, {});
      state.cartItems = (Object.keys(result || {}).length > 0 && result) || null;
      state.successMessage = action.payload.status_code;
    },
    updateCartItemsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getMyOrdersRequest(state) {
      state.isLoading = true;
    },
    getMyOrdersSuccess(state, action) {
      state.isLoading = false;
      state.myOrders = action.payload.data.orders;
      state.myOrderCurrentPage = action.payload.data.current_page;
      state.myOrderNextPage = action.payload.data.next_page;
      state.myOrderTotalPage = action.payload.data.total_page;
      state.successMessage = action.payload.status_code;
    },
    getMyOrdersFailure(state, action) {
      state.isLoading = false;
      state.myOrders = [];
      state.myOrderCurrentPage = null;
      state.myOrderNextPage = null;
      state.myOrderTotalPage = null;
      state.errorMessage = action.payload.status_code;
    },
    getEcomOrdersRequest(state) {
      state.isLoading = true;
      state.successMessage = null;
      state.errorMessage = null;
    },
    getEcomOrdersSuccess(state, action) {
      state.isLoading = false;
      state.ecomOrders = action.payload.data.orders;
      state.ecomOrdersCurrPage = action.payload.data.current_page;
      state.ecomOrdersNextPage = action.payload.data.next_page;
      state.ecomOrdersTotalPage = action.payload.data.total_page;
      state.successMessage = action.payload.status_code;
    },
    getEcomOrdersFailure(state, action) {
      state.isLoading = false;
      state.ecomOrders = [];
      state.ecomOrdersCurrPage = null;
      state.ecomOrdersNextPage = null;
      state.ecomOrdersTotalPage = null;
      state.errorMessage = action.payload.status_code;
    },
    getEcomOrderDetailsRequest(state) {
      state.isLoading = true;
      state.successMessage = null;
      state.errorMessage = null;
    },
    getEcomOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.ecomOrderDetails = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getEcomOrderDetailsFailure(state, action) {
      state.isLoading = false;
      state.ecomOrderDetails = {};
      state.errorMessage = action.payload.status_code;
    },
    updateEcomOrderDetailsRequest(state) {
      state.isLoading = true;
      state.successMessage = null;
      state.errorMessage = null;
    },
    updateEcomOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateEcomOrderDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    initiatePaymentRequest(state) {
      state.isLoading = true;
    },
    initiatePaymentSuccess(state, action) {
      state.isLoading = false;
      state.callBackUrl = action.payload.data.call_back_url;
      state.successMessage = action.payload.status_code;
    },
    initiatePaymentFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    setPaymentUrl(state, action) {
      state.callBackUrl = action.payload;
    },
    statusCheckRequest(state) {
      state.isLoading = true;
    },
    statusCheckSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    statusCheckFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getBatchesRequest(state) {
      state.isLoading = true;
    },
    getBatchesSuccess(state, action) {
      state.isLoading = false;
      state.batches = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getBatchesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.batches = [];
    },
    setBatches(state, action) {
      state.batches = action.payload;
    }
  }
});

export const {
  resetECommerce,
  resetECommerceMsg,
  setECommerceFeaturedCurrRender,
  setEcommerceRecentCurrRender,
  setActivitySearchText,
  getBannersFailure,
  getBannersRequest,
  getBannersSuccess,
  setECommerceFeaturedExpand,
  setEcommerceRecentExpand,
  setEcommerceProducListRecentExpand,
  setEcommerceProducListTabCurrRender,
  setPaymentUrl,
  getFeaturedProductsFailure,
  getFeaturedProductsRequest,
  getFeaturedProductsSuccess,
  setEcommerceFeaturedMaterialGrp,
  setEcommerceRecentMaterialGrp,
  setEcommerceActivityRecentMaterialGrp,
  getRecentProductsFailure,
  getRecentProductsRequest,
  getRecentProductsSuccess,
  getAdverticementFailure,
  getAdverticementRequest,
  getAdverticementSuccess,
  getBestDealsFailure,
  getBestDealsRequest,
  getBestDealsSuccess,
  getBestSellerFailure,
  getBestSellerRequest,
  getBestSellerSuccess,
  getHotFailure,
  getHotRequest,
  getHotSuccess,
  getNewArrivalFailure,
  getNewArrivalRequest,
  getNewArrivalSuccess,
  getActivityProductsFailure,
  getActivityProductsRequest,
  getActivityProductsSuccess,
  setCartItems,
  setCartOpen,
  setActivityFilter,
  getEcomProductDetailsFailure,
  getEcomProductDetailsRequest,
  getEcomProductDetailsSuccess,
  getCartItemsFailure,
  getCartItemsRequest,
  getCartItemsSuccess,
  addCartItemsFailure,
  addCartItemsRequest,
  addCartItemsSuccess,
  updateCartItemsFailure,
  updateCartItemsRequest,
  updateCartItemsSuccess,
  getMyOrdersRequest,
  getMyOrdersSuccess,
  getMyOrdersFailure,
  getEcomOrdersRequest,
  getEcomOrdersSuccess,
  getEcomOrdersFailure,
  getEcomOrderDetailsRequest,
  getEcomOrderDetailsSuccess,
  getEcomOrderDetailsFailure,
  updateEcomOrderDetailsRequest,
  updateEcomOrderDetailsSuccess,
  updateEcomOrderDetailsFailure,
  setManageOrderActionType,
  setEcomShippingAddress,
  initiatePaymentFailure,
  initiatePaymentRequest,
  initiatePaymentSuccess,
  statusCheckFailure,
  statusCheckRequest,
  statusCheckSuccess,
  getBatchesFailure,
  getBatchesRequest,
  getBatchesSuccess,
  setBatches
} = eCommerceSlice.actions;

export default eCommerceSlice.reducer;
