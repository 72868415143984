import { Box } from '@mui/material';
import { styles } from './styles';
import { Text } from '../../../../ui-kits';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { getTitles } from '../../../../utils';
import { CartCounter } from '../../utility-components';
import { useSelector } from 'react-redux';
import { authStatus, getUserData } from '../../../../services/redux/selectors';

export const EComProductCard = ({
  item,
  containerStyles,
  onTitleClick,
  count,
  addQty = () => {},
  minusQty = () => {},
  addCartClick = () => {},
  counterContainerStyle,
  countStyle,
  cartBtnStyle,
  imageStyle
}) => {
  const isAuthenticated = useSelector(authStatus);
  const userData = useSelector(getUserData);
  const minimumQty = 10;

  const handleAddToCart = () => {
    if (Number(item.balance_unit) >= 0) {
      addCartClick();
    }
  };

  return (
    <Box sx={styles.container(containerStyles)}>
      <Text
        style={styles.tag(
          item.mark_as?.id,
          item.free_for_employee && isAuthenticated && [1, 2].includes(userData?.user_role)
        )}
        variant="D18">
        {item.free_for_employee && isAuthenticated && [1, 2].includes(userData?.user_role)
          ? getTitles('T-11163').toUpperCase()
          : item.mark_as?.name?.replace('_', ' ')}
      </Text>
      <Box sx={styles.imgCont}>
        <img
          src={item.product_image && item.product_image[0]}
          style={styles.img(imageStyle)}
          alt="No image"
          onClick={onTitleClick}
        />
      </Box>

      <Text variant="BoldH20" style={styles.nameCont} onClick={onTitleClick}>
        {item.product_name}
      </Text>

      {Number(item.balance_unit) <= minimumQty && (
        <Text variant="fieldError" style={styles.outOfStock}>
          {Number(item.balance_unit) >= 0 &&
            `${getTitles('T-11738')} ${item.balance_unit} ${getTitles('T-11739')}`}
          {Number(item.balance_unit) <= 0 && getTitles('T-11740')}
        </Text>
      )}
      <Box sx={styles.priceCont}>
        <Text
          variant={
            'BoldH20'
          }>{`₹ ${item.free_for_employee && isAuthenticated && [1, 2].includes(userData?.user_role) ? 0 : item.final_price || ''}`}</Text>

        {count ? (
          <CartCounter
            count={count}
            onAddClick={addQty}
            onMinusClick={minusQty}
            containerStyles={counterContainerStyle}
            countStyle={countStyle}
            cartBtnStyle={cartBtnStyle}
            disabled={count === Number(item.balance_unit)}
          />
        ) : (
          <Box sx={styles.cartCont} onClick={handleAddToCart}>
            <ShoppingCartOutlinedIcon sx={styles.cartIcon} />
            <Text variant={'BoldH20'} style={styles.cartText}>
              {Number(item.balance_unit) <= 0
                ? getTitles('T-11740').toUpperCase()
                : getTitles('T-11600').toUpperCase()}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
