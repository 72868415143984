import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
const md = defaultValues.isMedium;
export const styles = {
  container: {
    width: '100%',
    mt: sm ? 0 : 2,
    display: res ? 'block' : 'flex',
    backgroundColor: color.primaryBackground,
    p: sm ? 1 : 2,
    boxSizing: 'border-box'
  },
  imageCont: {
    width: res ? '100%' : '20%',
    maxHeight: '60vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: res ? 'row' : 'column',
    gap: '10px',
    mt: sm ? 0 : res ? 1 : 2,
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    alignItems: 'center'
  },

  outOfStock: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%'
  },

  video: (selected) => ({
    maxWidth: sm ? '100%' : res ? '50%' : 'calc(100% - 4px)',
    maxHeight: res ? '200px' : '100px',
    border: res
      ? `2px solid transparent`
      : selected
        ? `2px solid ${color.palette.orange}`
        : `2px solid transparent`,
    cursor: 'pointer'
  }),

  img: (selected, moreThanOne) => ({
    maxWidth: sm ? (moreThanOne ? '90%' : '100%') : res ? '50%' : 'calc(100% - 4px)',
    maxHeight: res ? '200px' : '100px',
    border: res
      ? `2px solid transparent`
      : selected
        ? `2px solid ${color.palette.orange}`
        : `2px solid transparent`,
    cursor: 'pointer'
  }),

  mainImage: {
    width: '70%',
    height: '70%'
  },
  mainImageCont: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  left: {
    width: res ? '100%' : '50%',
    display: 'flex',
    p: 1,
    boxSizing: 'border-box'
  },

  right: {
    width: res ? '100%' : '50%',
    padding: res ? '7px' : '10px 20px 20px 20px',
    boxSizing: 'border-box',
    height: res ? 'fit-content' : '70vh',
    overflow: res ? 'none' : 'auto',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    position: 'relative',
    paddingBottom: res ? 0 : 0
  },

  proDetBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    mt: 1
  },
  descCont: {
    width: 'calc(50% - 10px)'
  },
  price: {
    color: color.palette.blue
  },
  divider: {
    my: 2
  },
  nameCont: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '10px'
  },
  cartCont: {
    mt: res ? 1 : 2,
    display: 'flex',
    gap: res ? '6px' : '10px',
    justifyContent: 'center',
    width: '100%',
    p: sm ? 0 : res ? 1 : 2,
    boxSizing: 'border-box',
    position: res ? 'sticky' : 'static',
    bottom: 0,
    left: 0,
    right: 0
  },
  addCartBtn: {
    width: res ? '50%' : md ? '40%' : '35%',
    fontSize: md ? '0.825rem' : '1rem',
    height: '45px'
  },
  counterCont: {
    width: res ? '50%' : md ? '40%' : '35%',
    fontSize: '1rem',
    height: '45px',
    justifyContent: 'space-between'
  },
  buyNowBtn: {
    width: res ? '50%' : md ? '40%' : '35%',
    height: '45px',
    fontSize: md ? '0.825rem' : '1rem',
    border: `2px solid ${color.primary}`,
    backgroundColor: color.primaryBackground,
    color: color.primary,
    '&:hover': {
      bgcolor: color.primary,
      color: color.tertiaryText
    }
  },
  returnText: {
    color: color.secondaryText,
    mt: res ? 2 : 0
  },
  colorText: {
    color: color.secondaryText,
    fontWeight: 600
  },
  tabCont: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  tab: (showBorder) => ({
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    p: 0.5,
    alignItems: 'center',
    borderBottom: `2px solid ${showBorder ? color.palette.royalBlue : 'transparent'}`,
    fontWeight: showBorder ? 600 : 400,
    backgroundColor: showBorder ? color.secondaryBackground : color.primaryBackground
  })
};
