import { useDispatch, useSelector } from 'react-redux';
import {
  addProductRequest,
  getCategoryListRequest,
  getStateRequest,
  updateDetailsPg,
  updateProductRequest
} from '../../../../services/redux/slices';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { defaultValues } from '../../../../constants';
import { UIContainer } from '../../../../ui-kits/container';
import {
  EditButton,
  UIButton,
  UIRadioButtonWithTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { deduplicateAndSet, getTitles, productMasterSchema } from '../../../../utils';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { styles } from './style';
import { getDetailsPg } from '../../../../services/redux/selectors';
import { useFormik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import {
  getGategoryList,
  getStateNextPage,
  getStates
} from '../../../../services/redux/selectors/manageProccess';

export const ViewAddEditProduct = ({
  actionType,
  setActionType,
  materialGroup,
  productData,
  units,
  isReadOnly,
  commonCategory
}) => {
  const { add, edit, view } = defaultValues.actionType;
  const { commonOrOther } = defaultValues.inventoryMaterialGroupId;
  const isRes = defaultValues.isResponsive;

  const dispatch = useDispatch();

  const showBackButton = useSelector(getDetailsPg);
  const states = useSelector(getStates);
  const stateNextPage = useSelector(getStateNextPage);
  const caterogies = useSelector(getGategoryList);

  const [require, setRequire] = useState(false);
  const [product, setProduct] = useState(null);
  const [btnDisabled, setBtndisabled] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const {
    values,
    errors,
    touched,
    dirty,
    setValues,
    handleBlur,
    setFieldValue,
    isValid,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      brand_name: '',
      manufacturer: '',
      hsn: '',
      return_policy: '',
      unit: { _id: '' },
      refrigeration_required: null,
      gst: '',
      state_supply: { _id: '' },
      material_group: { id: '' },
      category_enum: '',
      category_id: { _id: '' }
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: productMasterSchema
  });

  useEffect(() => {
    if (values.material_group?.id && values.material_group?.id !== commonOrOther) {
      dispatch(getCategoryListRequest(values.material_group?.id));
    } else {
      setCategoryList(commonCategory);
    }
    return () => {
      setCategoryList([]);
    };
  }, [values.material_group?.id]);

  useEffect(() => {
    if (caterogies) {
      setCategoryList(caterogies);
    }
  }, [caterogies]);

  useEffect(() => {
    if (values.material_group?.id && actionType !== view) {
      setFieldValue('category_id._id', '');
      setFieldValue('category_enum', '');
    }
  }, [values.material_group?.id, setFieldValue]);

  useEffect(() => {
    dispatch(getStateRequest());
  }, []);

  useEffect(() => {
    if (stateNextPage !== null) {
      dispatch(getStateRequest({ page: stateNextPage }));
    }
  }, [stateNextPage]);

  useEffect(() => {
    setStateList(deduplicateAndSet(stateList, states));
  }, [states]);

  useEffect(() => {
    actionType === view ? setRequire(false) : setRequire(true);
  }, [actionType]);

  useEffect(() => {
    if (!showBackButton) {
      handleBackButton();
    }
  }, [showBackButton]);

  useEffect(() => {
    productData && setProduct(productData);
  }, [productData]);

  useEffect(() => {
    if (product && actionType !== add) {
      setValues({
        ...product,
        category_enum: product.category_enum || '',
        category_id: product.category_id || { _id: '' }
      });
    }
  }, [product]);

  const handleBackButton = () => {
    setActionType(null);
    dispatch(updateDetailsPg(false));
  };

  const handleEditButton = () => {
    setActionType(edit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      material_group: values.material_group?.id,
      state_supply: values.state_supply?._id,
      category_id: values.category_id?._id ? values.category_id?._id : null,
      unit: values.unit?._id
    };
    if (values.category_enum === '') {
      delete payload.category_enum;
    }

    if (values.category_id === '') {
      delete payload.category_id;
    }
    if (actionType === edit) {
      payload._id = product?._id || '';
    }
    actionType === add && dispatch(addProductRequest(payload));
    actionType === edit && dispatch(updateProductRequest(payload));
    setActionType(null);
    dispatch(updateDetailsPg(false));
    resetForm();
  };

  return (
    <>
      {!isRes ? (
        <UIBackButton onClick={handleBackButton} backRoot={styles.backBtn} />
      ) : (
        <Box sx={styles.resEditCont}>
          {actionType === view && !isReadOnly && (
            <UIButton
              startIcon={<EditIcon />}
              style={styles.resEditBtn}
              onClick={handleEditButton}
            />
          )}
        </Box>
      )}
      <UIContainer styles={styles.mainCont}>
        {actionType === view && !isRes && !isReadOnly && (
          <UIButton
            title={getTitles('T-10084')}
            startIcon={<EditButton iconStyle={styles.editIcon} />}
            style={styles.editBtn}
            onClick={handleEditButton}
          />
        )}

        <Grid container spacing={2} sx={styles.gridCont}>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="name"
              title={getTitles('T-11437')}
              value={values.name}
              onChange={(val) => {
                handleChange(val);
                setBtndisabled(false);
              }}
              placeHolder={getTitles('T-10586')}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              required={require}
              disabled={actionType === view}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="brand_name"
              title={getTitles('T-11494')}
              placeHolder={getTitles('T-10384')}
              value={values.brand_name}
              error={touched.brand_name && errors.brand_name}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={actionType === view}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="manufacturer"
              title={getTitles('T-11741')}
              placeHolder={getTitles('T-10384')}
              value={values.manufacturer}
              error={touched.manufacturer && errors.manufacturer}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={actionType === view}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={styles.gridCont}>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="return_policy"
              title={getTitles('T-11533')}
              placeHolder={getTitles('T-10384')}
              value={values.return_policy}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.return_policy && errors.return_policy}
              readOnly={actionType === view}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="hsn"
              title={getTitles('T-11516')}
              placeHolder={getTitles('T-10642')}
              value={values.hsn}
              error={touched.hsn && errors.hsn}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={actionType === view}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UISelect
              name="unit._id"
              title={getTitles('T-11440')}
              options={units}
              optionLabel="name"
              returnValue={'_id'}
              placeholder={getTitles('T-10350')}
              required={require}
              value={values.unit?._id}
              valueToShow={values.unit?.name}
              onChange={handleChange}
              error={touched.unit?._id && errors.unit?._id}
              onBlur={handleBlur}
              onlyView={actionType === view}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={styles.gridCont}>
          <Grid item xs={12} md={4} xl={3}>
            <UIRadioButtonWithTitle
              name="refrigeration_required"
              title={getTitles('T-11534')}
              required={require}
              checkedValue={values.refrigeration_required}
              onCheck={(val) => {
                handleChange({ target: { name: 'refrigeration_required', value: val } });
                setBtndisabled(false);
              }}
              values={[
                { title: getTitles('T-10705'), value: true },
                { title: getTitles('T-10706'), value: false }
              ]}
              itemTitleStyle={styles.radioItemTitleStyle}
              error={touched.refrigeration_required && errors.refrigeration_required}
              disabled={actionType === view}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UITextInputWithTitle
              name="gst"
              title={getTitles('T-11526')}
              placeHolder={getTitles('T-11537')}
              error={touched.gst && errors.gst}
              value={values.gst}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={actionType === view}
              required
              allowDecimal
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UISelect
              title={getTitles('T-11519')}
              options={stateList}
              returnValue="_id"
              optionLabel="name"
              name="state_supply._id"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.state_supply?._id && errors.state_supply?._id}
              value={values.state_supply._id}
              disabled={actionType === view}
              placeholder={getTitles('T-10350')}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={styles.gridCont}>
          {actionType !== add && (
            <Grid item xs={12} md={4} xl={3}>
              <UITextInputWithTitle
                name="product_id"
                title={getTitles('T-11457')}
                value={product?.product_id || ''}
                placeHolder={getTitles('T-11458')}
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4} xl={3}>
            <UISelect
              name="material_group.id"
              title={getTitles('T-10180')}
              value={values.material_group?.id}
              valueToShow={values.material_group?.name}
              options={materialGroup}
              optionLabel="name"
              returnValue={'id'}
              placeholder={getTitles('T-10350')}
              onChange={handleChange}
              error={touched.material_group?.id && errors.material_group?.id}
              onBlur={handleBlur}
              required={require}
              onlyView={actionType === view}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <UISelect
              name={
                values.material_group?.id === commonOrOther ? 'category_id._id' : 'category_enum'
              }
              title={getTitles('T-11471')}
              options={categoryList}
              returnValue={values.material_group?.id === commonOrOther ? '_id' : 'category_name'}
              optionLabel={values.material_group?.id === commonOrOther ? 'name' : 'category_name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values.material_group?.id === commonOrOther
                  ? values.category_id?._id
                  : values.category_enum || ''
              }
              readOnly={actionType === view || !values.material_group?.id}
              error={
                values.material_group?.id === commonOrOther
                  ? touched.category_id && errors.category_id
                  : touched.category_enum && errors.category_enum
              }
              placeholder={getTitles('T-10350')}
              required
            />
          </Grid>
        </Grid>

        {actionType !== view && (
          <Grid container sx={styles.gridCont} marginTop={3}>
            <Grid item xs={12} md={4} xl={3}>
              <ModalButtons
                btnPositions={'flex-start'}
                containerStyle={styles.buttonCont}
                primaryBtnTitle={
                  actionType === add
                    ? getTitles('T-10394')
                    : actionType === edit && getTitles('T-10046')
                }
                onPrimaryBtnClick={actionType !== view && handleSubmit}
                disabled={!isValid || !dirty || btnDisabled}
              />
            </Grid>
          </Grid>
        )}
      </UIContainer>
    </>
  );
};
