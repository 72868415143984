import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addDiseaseAPI,
  addDistrictAPI,
  addMasterListAPI,
  addProductAPI,
  addProductVariantAPI,
  addProfilesAPI,
  addStateAPI,
  addStoreApi,
  addTalukAPI,
  addVaccineAPI,
  addVenderApi,
  addVillageAPI,
  getCatergoriesAPI,
  getDiseaseAPI,
  getDistrictAPI,
  getDistrictByStateAPI,
  getEmployeeByPermissionApi,
  getMasterListAPI,
  getMasterListsAPI,
  getProductsAPI,
  getProductVariantssAPI,
  getProfileDetailsAPI,
  getProfilesAPI,
  getStateAPI,
  getStoreApi,
  getStoreDetailsApi,
  getTalukaByDistrictAPI,
  getTalukAPI,
  getVaccineAPI,
  getVenderApi,
  getVillageAPI,
  getVillageByTalukaAPI,
  getVillageDetailsAPI,
  updateDiseaseAPI,
  updateDistrictAPI,
  updateMasterListAPI,
  updateProductAPI,
  updateProductVariantAPI,
  updateProfilesAPI,
  updateStateAPI,
  updateStoreApi,
  updateTalukAPI,
  updateVaccineAPI,
  updateVenderApi,
  updateVillageAPI,
  updateVillageStatusAPI
} from '../../api';
import {
  addDiseaseFailure,
  addDiseaseRequest,
  addDiseaseSuccess,
  addDistrictFailure,
  addDistrictRequest,
  addDistrictSuccess,
  addMasterListFailure,
  addMasterListRequest,
  addMasterListSuccess,
  addStateFailure,
  addStateRequest,
  addStateSuccess,
  addTalukFailure,
  addTalukRequest,
  addTalukSuccess,
  addVillageFailure,
  addVillageRequest,
  addVillageSuccess,
  getDiseaseFailure,
  getDiseaseRequest,
  getDiseaseSuccess,
  getDistrictFailure,
  getDistrictRequest,
  getDistrictSuccess,
  getDiseaseGroupFailure,
  getDiseaseGroupRequest,
  getDiseaseGroupSuccess,
  getDiseaseTypeFailure,
  getDiseaseTypeRequest,
  getDiseaseTypeSuccess,
  getMasterListFailure,
  getMasterListRequest,
  getMasterListSuccess,
  getStateFailure,
  getStateRequest,
  getStateSuccess,
  getTalukFailure,
  getTalukRequest,
  getTalukSuccess,
  getVillageFailure,
  getVillageRequest,
  getVillageSuccess,
  updateDiseaseFailure,
  updateDiseaseRequest,
  updateDiseaseSuccess,
  updateDistrictFailure,
  updateDistrictRequest,
  updateDistrictSuccess,
  updateMasterListFailure,
  updateMasterListRequest,
  updateMasterListSuccess,
  updateStateFailure,
  updateStateRequest,
  updateStateSuccess,
  updateTalukFailure,
  updateTalukRequest,
  updateTalukSuccess,
  updateVillageFailure,
  updateVillageRequest,
  updateVillageSuccess,
  getVaccineSuccess,
  getVaccineFailure,
  addVaccineSuccess,
  addVaccineFailure,
  updateVaccineSuccess,
  updateVaccineFailure,
  getVaccineRequest,
  addVaccineRequest,
  updateVaccineRequest,
  getMasterListWiseSuccess,
  getMasterListWiseFailure,
  getMasterListWiseRequest,
  getVillageDetailsSuccess,
  getVillageDetailsFailure,
  getVillageDetailsRequest,
  updateVillageStatusFailure,
  updateVillageStatusSuccess,
  updateVillageStatusRequest,
  getProfilesFailure,
  getProfilesRequest,
  getProfilesSuccess,
  addProfilingSuccess,
  addProfilingFailure,
  addProfilingRequest,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  getProfileDetailsRequest,
  updateProfileDetailsSuccess,
  updateProfileDetailsFailure,
  updateProfileDetailsRequest,
  getDistrictByStateSuccess,
  getDistrictByStateFailure,
  getDistrictByStateRequest,
  getTalukaByDistrictSuccess,
  getTalukaByDistrictFailure,
  getTalukaByDistrictRequest,
  getVillageByTalukaFailure,
  getVillageByTalukaSuccess,
  getVillageByTalukaRequest,
  getStoreFailure,
  getStoreSuccess,
  getStoreRequest,
  getStoreManagerSuccess,
  getStoreManagerFailure,
  getStoreManagerRequest,
  addStoreFailure,
  addStoreSuccess,
  updateStoreRequest,
  addStoreRequest,
  updateStoreSuccess,
  updateStoreFailure,
  getStoreDetailsSuccess,
  getStoreDetailsFailure,
  getStoreDetailsRequest,
  updateVenderFailure,
  updateVenderSuccess,
  addVenderFailure,
  addVenderSuccess,
  getVenderFailure,
  getVenderSuccess,
  getVenderRequest,
  addVenderRequest,
  updateVenderRequest,
  getProductFailure,
  getProductSuccess,
  getProductRequest,
  addProductSuccess,
  addProductFailure,
  addProductRequest,
  updateProductSuccess,
  updateProductFailure,
  updateProductRequest,
  getCategoryListSuccess,
  getCategoryListFailure,
  getCategoryListRequest,
  getProductVariantsSuccess,
  getProductVariantsFailure,
  addProductVariantSuccess,
  addProductVariantFailure,
  updateProductVariantSuccess,
  updateProductVariantFailure,
  getProductVariantsRequest,
  addProductVariantRequest,
  updateProductVariantRequest
} from '../slices';

function* addState(action) {
  try {
    const response = yield addStateAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addStateSuccess(response.data));
    } else {
      yield put(addStateFailure(response.data));
    }
  } catch (error) {
    yield put(addStateFailure(error.response.data));
  }
}

function* getState(action) {
  try {
    const response = yield getStateAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getStateSuccess(response.data));
    } else {
      yield put(getStateFailure(response.data));
    }
  } catch (error) {
    yield put(getStateFailure(error.response.data));
  }
}

function* updateState(action) {
  try {
    const response = yield updateStateAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateStateSuccess(response.data));
    } else {
      yield put(updateStateFailure(response.data));
    }
  } catch (error) {
    yield put(updateStateFailure(error.response.data));
  }
}

function* addDistrict(action) {
  try {
    const response = yield addDistrictAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addDistrictSuccess(response.data));
    } else {
      yield put(addDistrictFailure(response.data));
    }
  } catch (error) {
    yield put(addDistrictFailure(error.response.data));
  }
}

function* getDistrict(action) {
  try {
    const response = yield getDistrictAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDistrictSuccess(response.data));
    } else {
      yield put(getDistrictFailure(response.data));
    }
  } catch (error) {
    yield put(getDistrictFailure(error.response.data));
  }
}

function* updateDistrict(action) {
  try {
    const response = yield updateDistrictAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateDistrictSuccess(response.data));
    } else {
      yield put(updateDistrictFailure(response.data));
    }
  } catch (error) {
    yield put(updateDistrictFailure(error.response.data));
  }
}

function* addTaluk(action) {
  try {
    const response = yield addTalukAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addTalukSuccess(response.data));
    } else {
      yield put(addTalukFailure(response.data));
    }
  } catch (error) {
    yield put(addTalukFailure(error.response.data));
  }
}

function* getTaluk(action) {
  try {
    const response = yield getTalukAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getTalukSuccess(response.data));
    } else {
      yield put(getTalukFailure(response.data));
    }
  } catch (error) {
    yield put(getTalukFailure(error.response.data));
  }
}

function* updateTaluk(action) {
  try {
    const response = yield updateTalukAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateTalukSuccess(response.data));
    } else {
      yield put(updateTalukFailure(response.data));
    }
  } catch (error) {
    yield put(updateTalukFailure(error.response.data));
  }
}

function* addVillage(action) {
  try {
    const response = yield addVillageAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addVillageSuccess(response.data));
    } else {
      yield put(addVillageFailure(response.data));
    }
  } catch (error) {
    yield put(addVillageFailure(error.response.data));
  }
}

function* getVillage(action) {
  try {
    const response = yield getVillageAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVillageSuccess(response.data));
    } else {
      yield put(getVillageFailure(response.data));
    }
  } catch (error) {
    yield put(getVillageFailure(error.response.data));
  }
}

function* updateVillage(action) {
  try {
    const response = yield updateVillageAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateVillageSuccess(response.data));
    } else {
      yield put(updateVillageFailure(response.data));
    }
  } catch (error) {
    yield put(updateVillageFailure(error.response.data));
  }
}

function* getMasterList(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getMasterListSuccess(response.data));
    } else {
      yield put(getMasterListFailure(response.data));
    }
  } catch (error) {
    yield put(getMasterListFailure(error.response.data));
  }
}

function* getMasterList16(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDiseaseGroupSuccess(response.data));
    } else {
      yield put(getDiseaseGroupFailure(response.data));
    }
  } catch (error) {
    yield put(getMasterListFailure(error.response.data));
  }
}
function* getMasterList17(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDiseaseTypeSuccess(response.data));
    } else {
      yield put(getDiseaseTypeFailure(response.data));
    }
  } catch (error) {
    yield put(getMasterListFailure(error.response.data));
  }
}

function* addMasterList(action) {
  try {
    const response = yield addMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addMasterListSuccess(response.data));
    } else {
      yield put(addMasterListFailure(response.data));
    }
  } catch (error) {
    yield put(addMasterListFailure(error.response.data));
  }
}

function* updateMasterList(action) {
  try {
    const response = yield updateMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateMasterListSuccess(response.data));
    } else {
      yield put(updateMasterListFailure(response.data));
    }
  } catch (error) {
    yield put(updateMasterListFailure(error.response.data));
  }
}

function* getDisease(action) {
  try {
    const response = yield getDiseaseAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDiseaseSuccess(response.data));
    } else {
      yield put(getDiseaseFailure(response.data));
    }
  } catch (error) {
    yield put(getDiseaseFailure(error.response.data));
  }
}

function* addDisease(action) {
  try {
    const response = yield addDiseaseAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addDiseaseSuccess(response.data));
    } else {
      yield put(addDiseaseFailure(response.data));
    }
  } catch (error) {
    yield put(addDiseaseFailure(error.response.data));
  }
}

function* updateDisease(action) {
  try {
    const response = yield updateDiseaseAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateDiseaseSuccess(response.data));
    } else {
      yield put(updateDiseaseFailure(response.data));
    }
  } catch (error) {
    yield put(updateDiseaseFailure(error.response.data));
  }
}

function* getVaccines(action) {
  try {
    const response = yield getVaccineAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVaccineSuccess(response.data));
    } else {
      yield put(getVaccineFailure(response.data));
    }
  } catch (error) {
    yield put(getVaccineFailure(error.response.data));
  }
}

function* addVaccine(action) {
  try {
    const response = yield addVaccineAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addVaccineSuccess(response.data));
    } else {
      yield put(addVaccineFailure(response.data));
    }
  } catch (error) {
    yield put(addVaccineFailure(error.response.data));
  }
}

function* updateVaccine(action) {
  try {
    const response = yield updateVaccineAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateVaccineSuccess(response.data));
    } else {
      yield put(updateVaccineFailure(response.data));
    }
  } catch (error) {
    yield put(updateVaccineFailure(error.response.data));
  }
}

function* getMasterLists(action) {
  try {
    const response = yield getMasterListsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getMasterListWiseSuccess(response.data));
    } else {
      yield put(getMasterListWiseFailure(response.data));
    }
  } catch (error) {
    yield put(getMasterListWiseFailure(error.response.data));
  }
}

function* getVillageDetails(action) {
  try {
    const response = yield getVillageDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVillageDetailsSuccess(response.data));
    } else {
      yield put(getVillageDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getVillageDetailsFailure(error.response.data));
  }
}

function* updateVillageStatus(action) {
  try {
    const response = yield updateVillageStatusAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateVillageStatusSuccess(response.data));
    } else {
      yield put(updateVillageStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateVillageStatusFailure(error.response.data));
  }
}

function* getProfiles(action) {
  try {
    const response = yield getProfilesAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getProfilesSuccess(response.data));
    } else {
      yield put(getProfilesFailure(response.data));
    }
  } catch (error) {
    yield put(getProfilesFailure(error.response.data));
  }
}

function* addProfilings(action) {
  try {
    const response = yield addProfilesAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addProfilingSuccess(response.data));
    } else {
      yield put(addProfilingFailure(response.data));
    }
  } catch (error) {
    yield put(addProfilingFailure(error.response.data));
  }
}

function* getProfileDetails(action) {
  try {
    const response = yield getProfileDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getProfileDetailsSuccess(response.data));
    } else {
      yield put(getProfileDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getProfileDetailsFailure(error.response.data));
  }
}

function* updateDiseaseAPIProfilings(action) {
  try {
    const response = yield updateProfilesAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateProfileDetailsSuccess(response.data));
    } else {
      yield put(updateProfileDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(updateProfileDetailsFailure(error.response.data));
  }
}

function* getDistrictsByState(action) {
  try {
    const response = yield getDistrictByStateAPI(action.payload);

    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDistrictByStateSuccess(response.data));
    } else {
      yield put(getDistrictByStateFailure(response.data));
    }
  } catch (error) {
    yield put(getDistrictByStateFailure(error.response.data));
  }
}

function* getTalukasByDistrict(action) {
  try {
    const response = yield getTalukaByDistrictAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getTalukaByDistrictSuccess(response.data));
    } else {
      yield put(getTalukaByDistrictFailure(response.data));
    }
  } catch (error) {
    yield put(getTalukaByDistrictFailure(error.response.data));
  }
}

function* getVillagesByTaluka(action) {
  try {
    const response = yield getVillageByTalukaAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVillageByTalukaSuccess(response.data));
    } else {
      yield put(getVillageByTalukaFailure(response.data));
    }
  } catch (error) {
    yield put(getVillageByTalukaFailure(error.response.data));
  }
}

function* getStores(action) {
  try {
    const response = yield getStoreApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getStoreSuccess(response.data));
    } else {
      yield put(getStoreFailure(response.data));
    }
  } catch (error) {
    yield put(getStoreFailure(error.response.data));
  }
}

function* getStoreManagers(action) {
  try {
    const response = yield getEmployeeByPermissionApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getStoreManagerSuccess(response.data));
    } else {
      yield put(getStoreManagerFailure(response.data));
    }
  } catch (error) {
    yield put(getStoreManagerFailure(error.response.data));
  }
}

function* addStore(action) {
  try {
    const response = yield addStoreApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addStoreSuccess(response.data));
    } else {
      yield put(addStoreFailure(response.data));
    }
  } catch (error) {
    yield put(addStoreFailure(error.response.data));
  }
}

function* updateStore(action) {
  try {
    const response = yield updateStoreApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateStoreSuccess(response.data));
    } else {
      yield put(updateStoreFailure(response.data));
    }
  } catch (error) {
    yield put(updateStoreFailure(error.response.data));
  }
}

function* storeDetails(action) {
  try {
    const response = yield getStoreDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getStoreDetailsSuccess(response.data));
    } else {
      yield put(getStoreDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getStoreDetailsFailure(error.response.data));
  }
}

function* getVenders(action) {
  try {
    const response = yield getVenderApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVenderSuccess(response.data));
    } else {
      yield put(getVenderFailure(response.data));
    }
  } catch (error) {
    yield put(getVenderFailure(error.response.data));
  }
}

function* addVender(action) {
  try {
    const response = yield addVenderApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addVenderSuccess(response.data));
    } else {
      yield put(addVenderFailure(response.data));
    }
  } catch (error) {
    yield put(addVenderFailure(error.response.data));
  }
}

function* updateVender(action) {
  try {
    const response = yield updateVenderApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateVenderSuccess(response.data));
    } else {
      yield put(updateVenderFailure(response.data));
    }
  } catch (error) {
    yield put(updateVenderFailure(error.response.data));
  }
}

function* getProductDetails(action) {
  try {
    const response = yield getProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getProductSuccess(response.data));
    } else {
      yield put(getProductFailure(response.data));
    }
  } catch (error) {
    yield put(getProductFailure(error.response.data));
  }
}

function* addProductData(action) {
  try {
    const response = yield addProductAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addProductSuccess(response.data));
    } else {
      yield put(addProductFailure(response.data));
    }
  } catch (error) {
    yield put(addProductFailure(error.response.data));
  }
}

function* updateProductData(action) {
  try {
    const response = yield updateProductAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateProductSuccess(response.data));
    } else {
      yield put(updateProductFailure(response.data));
    }
  } catch (error) {
    yield put(updateProductFailure(error.response.data));
  }
}

function* getProductVariants(action) {
  try {
    const response = yield getProductVariantssAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getProductVariantsSuccess(response.data));
    } else {
      yield put(getProductVariantsFailure(response.data));
    }
  } catch (error) {
    yield put(getProductVariantsFailure(error.response.data));
  }
}

function* addProductVariants(action) {
  try {
    const response = yield addProductVariantAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addProductVariantSuccess(response.data));
    } else {
      yield put(addProductVariantFailure(response.data));
    }
  } catch (error) {
    yield put(addProductVariantFailure(error.response.data));
  }
}

function* updateProductVariants(action) {
  try {
    const response = yield updateProductVariantAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateProductVariantSuccess(response.data));
    } else {
      yield put(updateProductVariantFailure(response.data));
    }
  } catch (error) {
    yield put(updateProductVariantFailure(error.response.data));
  }
}

function* getCategories(action) {
  try {
    const response = yield getCatergoriesAPI(action.payload);
    if (response.data) {
      yield put(getCategoryListSuccess(response.data));
    } else {
      yield put(getCategoryListFailure(response.data));
    }
  } catch (error) {
    yield put(getCategoryListFailure(error.response.data));
  }
}

export function* manageProcessSaga() {
  yield takeLatest(addStateRequest.type, addState);
  yield takeLatest(getStateRequest.type, getState);
  yield takeLatest(updateStateRequest.type, updateState);
  yield takeLatest(addDistrictRequest.type, addDistrict);
  yield takeLatest(getDistrictRequest.type, getDistrict);
  yield takeLatest(updateDistrictRequest.type, updateDistrict);
  yield takeLatest(addTalukRequest.type, addTaluk);
  yield takeLatest(getTalukRequest.type, getTaluk);
  yield takeLatest(updateTalukRequest.type, updateTaluk);
  yield takeLatest(addVillageRequest.type, addVillage);
  yield takeLatest(getVillageRequest.type, getVillage);
  yield takeLatest(updateVillageRequest.type, updateVillage);
  yield takeLatest(getMasterListRequest.type, getMasterList);
  yield takeLatest(addMasterListRequest.type, addMasterList);
  yield takeLatest(updateMasterListRequest.type, updateMasterList);
  yield takeLatest(getDiseaseRequest.type, getDisease);
  yield takeLatest(addDiseaseRequest.type, addDisease);
  yield takeLatest(updateDiseaseRequest.type, updateDisease);
  yield takeLatest(getDiseaseGroupRequest.type, getMasterList16);
  yield takeLatest(getDiseaseTypeRequest.type, getMasterList17);
  yield takeLatest(getVaccineRequest.type, getVaccines);
  yield takeLatest(addVaccineRequest.type, addVaccine);
  yield takeLatest(updateVaccineRequest.type, updateVaccine);
  yield takeLatest(getMasterListWiseRequest.type, getMasterLists);
  yield takeLatest(getVillageDetailsRequest.type, getVillageDetails);
  yield takeLatest(updateVillageStatusRequest.type, updateVillageStatus);
  yield takeLatest(getProfilesRequest.type, getProfiles);
  yield takeLatest(addProfilingRequest.type, addProfilings);
  yield takeLatest(getProfileDetailsRequest.type, getProfileDetails);
  yield takeLatest(updateProfileDetailsRequest.type, updateDiseaseAPIProfilings);
  yield takeLatest(getDistrictByStateRequest.type, getDistrictsByState);
  yield takeLatest(getTalukaByDistrictRequest.type, getTalukasByDistrict);
  yield takeLatest(getVillageByTalukaRequest.type, getVillagesByTaluka);
  yield takeLatest(getStoreRequest.type, getStores);
  yield takeLatest(getStoreManagerRequest.type, getStoreManagers);
  yield takeLatest(addStoreRequest.type, addStore);
  yield takeLatest(updateStoreRequest.type, updateStore);
  yield takeLatest(getStoreDetailsRequest.type, storeDetails);
  yield takeLatest(getVenderRequest.type, getVenders);
  yield takeLatest(addVenderRequest.type, addVender);
  yield takeLatest(updateVenderRequest.type, updateVender);

  yield takeLatest(getProductRequest.type, getProductDetails);
  yield takeLatest(addProductRequest.type, addProductData);
  yield takeLatest(updateProductRequest.type, updateProductData);
  yield takeLatest(getCategoryListRequest.type, getCategories);
  yield takeLatest(getProductVariantsRequest.type, getProductVariants);
  yield takeLatest(addProductVariantRequest.type, addProductVariants);
  yield takeLatest(updateProductVariantRequest.type, updateProductVariants);
}
