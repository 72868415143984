import { Box } from '@mui/material';
import { styles } from './styles';
import { getTitles, InwardVendorDetailsSchema } from '../../../../utils';
import { useFormik } from 'formik';
import {
  DatePickers,
  UIAutocompleted,
  UICheckBox,
  UIRadioButton,
  UISelect,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInwardActivePage,
  getInwardEmployees,
  getProductDetails,
  getScroll
} from '../../../../services/redux/selectors';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';
import {
  addInventoryProductRequest,
  getInwardEmployeeRequest,
  getProductRequest,
  getProductVariantsRequest,
  getStoreRequest,
  getVenderRequest,
  updateInventoryProductRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import dayjs from 'dayjs';
import { getProductVariants, getStore } from '../../../../services/redux/selectors/manageProccess';

export const InwardVendorDetails = ({ vendor, productList, materialGroup }) => {
  const dispatch = useDispatch();
  const activePage = useSelector(getInwardActivePage);
  const productData = useSelector(getProductDetails);
  const employeeList = useSelector(getInwardEmployees);
  const storeList = useSelector(getStore);
  const scroll = useSelector(getScroll);
  const { store, employee } = defaultValues.inwardAssignTo;
  const { vendorType, variantType } = defaultValues;
  const variantsList = useSelector(getProductVariants);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [proDetails, setProDetails] = useState(null);
  const { productMaster } = defaultValues.appLogicalTexts;

  let timer;
  const { view, edit, add } = defaultValues.actionType;
  const { vetDoctor, aiWorker, laboratoryIncharge } = defaultValues.employeeRol;

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldTouched,
    setValues,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      product_master_id: { _id: '' },
      variant_id: { _id: '' },
      vendor_id: { _id: '' },
      bill_number: '',
      bill_date: null,
      manufacturing_date: null,
      expiry_date: null,
      batch_no: '',
      mrp_pack: '',
      unit_price: '',
      total_unit: '',
      free_qty: '',
      discount: '',
      shipping: '',
      assign_to: null,
      store_id: { _id: '' },
      employee_id: { _id: '' },
      mark_as: '',
      free_for_employee: false,
      is_featured: false
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: InwardVendorDetailsSchema
  });

  useEffect(() => {
    dispatch(updateScrollToTop(!scroll));
  }, []);

  useEffect(() => {
    if (values.product_master_id) {
      dispatch(
        getProductVariantsRequest({ product_master_id: values?.product_master_id?.product_id })
      );
    }
  }, [values.product_master_id]);

  useEffect(() => {
    setProDetails(productData);
  }, [productData]);

  useEffect(() => {
    values.assign_to === store &&
      dispatch(getStoreRequest({ filter: JSON.stringify([{ is_active: true }]) }));
    values.assign_to === employee &&
      dispatch(
        getInwardEmployeeRequest({
          employee_permission_list: JSON.stringify([vetDoctor, aiWorker, laboratoryIncharge])
        })
      );
  }, [values.assign_to]);

  useEffect(() => {
    if (proDetails) {
      const newData = {
        ...proDetails,
        bill_date: proDetails?.bill_date && dayjs(proDetails.bill_date),
        expiry_date: proDetails?.expiry_date && dayjs(proDetails.expiry_date),
        manufacturing_date: proDetails?.manufacturing_date && dayjs(proDetails.manufacturing_date),
        store_id: proDetails?.store_id ? proDetails.store_id : { _id: '' },
        employee_id: proDetails?.employee_id ? proDetails.employee_id : { _id: '' }
      };
      setValues(newData);
    }
  }, [proDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const searchList = (val, type) => {
    if (type === productMaster)
      dispatch(
        getProductRequest({
          search_text: val || null,
          filter: JSON.stringify([{ is_active: true }, { material_group: materialGroup }])
        })
      );
    if (type === store) dispatch(getStoreRequest({ search_text: val }));
    if (type === employee)
      dispatch(
        getInwardEmployeeRequest({
          search_text: val,
          employee_permission_list: JSON.stringify([vetDoctor, aiWorker, laboratoryIncharge])
        })
      );
    if (type === vendorType) {
      dispatch(getVenderRequest({ search_text: val }));
    }
    if (type === variantType) {
      dispatch(
        getProductVariantsRequest({
          product_master_id: values?.product_master_id?._id,
          search_text: val
        })
      );
    }
  };

  const transformValues = (values) => {
    return {
      product_master_id: values.product_master_id?._id,
      variant_id: values.variant_id?._id,
      vendor_id: values.vendor_id?._id,
      bill_number: values.bill_number,
      bill_date: values.bill_date,
      manufacturing_date: values.manufacturing_date,
      mrp_pack: Number(values.mrp_pack) || 0,
      unit_price: parseFloat(values.unit_price) || 0,
      free_qty: Number(values.free_qty) || 0,
      discount: parseFloat(values.discount) || 0,
      shipping: parseFloat(values.shipping) || 0,
      total_unit: Number(values.total_unit) || 0,
      assign_to: values.assign_to,
      store_id: values.assign_to === 1 ? values.store_id?._id : undefined,
      employee_id: values.assign_to === 2 ? values.employee_id?._id : undefined,
      mark_as: values.mark_as ?? 2.89,
      free_for_employee: Boolean(values.free_for_employee),
      is_featured: Boolean(values.is_featured)
    };
  };

  const handleSubmit = () => {
    const formattedValues = transformValues(values);

    if (activePage === add) {
      dispatch(addInventoryProductRequest(formattedValues));
    } else if (activePage === edit) {
      formattedValues._id = proDetails._id;
      dispatch(updateInventoryProductRequest(formattedValues));
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UIAutocompleted
          name="product_master_id"
          required={true}
          options={productList}
          title={getTitles('T-11437')}
          containerStyle={styles.autoCont}
          optionLabel={(item) => (item.name ? item.name : '')}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2 || val.length === 0) {
                searchList(val, productMaster);
              }
            }, 500);
          }}
          onBlur={handleBlur}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('product_master_id', val ? val : { _id: '' });
            setBtndisabled(false);
          }}
          preSelectedValue={values.product_master_id?._id && values.product_master_id}
          error={touched.product_master_id && errors.product_master_id?._id}
          readOnly={activePage === view}
          titleStyle={styles.autoTitle}
          width={styles.imgConWidth}
        />
        <UIAutocompleted
          name="variant_id"
          required={true}
          options={variantsList}
          title={getTitles('T-11745')}
          containerStyle={styles.autoCont}
          optionLabel={(item) => (item.variant_name ? item.variant_name : '')}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2 || val.length === 0) {
                searchList(val, 'variant');
              }
            }, 500);
          }}
          onBlur={handleBlur}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('variant_id', val ? val : { _id: '' });
            setBtndisabled(false);
          }}
          preSelectedValue={values.variant_id}
          error={touched.variant_id && errors.variant_id?._id}
          readOnly={activePage === view}
          titleStyle={styles.autoTitle}
          width={styles.imgConWidth}
        />
      </Box>
      <Box sx={styles.nameCont}>
        <UIAutocompleted
          name="vendor_id"
          required={true}
          options={vendor}
          title={getTitles('T-11450')}
          containerStyle={styles.autoCont}
          optionLabel={(item) =>
            item.first_name ? item.first_name + ' ' + item.last_name : item.name ? item.name : ''
          }
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2 || val.length === 0) {
                searchList(val, 'vendor');
              }
            }, 500);
          }}
          onBlur={handleBlur}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('vendor_id', val ? val : { _id: '' });
            setBtndisabled(false);
          }}
          preSelectedValue={values.vendor_id}
          error={touched.vendor_id && errors.vendor_id?._id}
          readOnly={activePage === view}
          titleStyle={styles.autoTitle}
          width={styles.imgConWidth}
        />
        <UITextInputWithTitle
          name="bill_number"
          title={getTitles('T-11518')}
          placeHolder={getTitles('T-10360')}
          error={touched.bill_number && errors.bill_number}
          value={values.bill_number}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
        />
      </Box>

      <Box sx={styles.nameCont}>
        <DatePickers
          dateValue={values.bill_date}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'bill_date' } })}
          title={getTitles('T-11482')}
          width={styles.imgConWidth}
          required={true}
          error={touched.bill_date && errors.bill_date}
          onOpen={() => setFieldTouched('bill_date', true)}
          disableFuture
        />

        <DatePickers
          dateValue={values.manufacturing_date}
          onDateChange={(val) =>
            handleChange({ target: { value: val, name: 'manufacturing_date' } })
          }
          title={getTitles('T-11483')}
          width={styles.imgConWidth}
          required={true}
          error={touched.manufacturing_date && errors.manufacturing_date}
          onOpen={() => setFieldTouched('manufacturing_date', true)}
          disableFuture
        />
      </Box>
      {materialGroup === 3 && (
        <Box sx={styles.nameCont}>
          <DatePickers
            dateValue={values.expiry_date}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'expiry_date' } })}
            title={getTitles('T-10743')}
            width={styles.imgConWidth}
            required={true}
            error={touched.expiry_date && errors.expiry_date}
            onOpen={() => setFieldTouched('expiry_date', true)}
            disableFuture
          />

          <UITextInputWithTitle
            name="batch_no"
            title={getTitles('T-11481')}
            placeHolder={getTitles('T-10360')}
            error={touched.batch_no && errors.batch_no}
            value={values.batch_no}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={activePage === view}
            width={styles.imgConWidth}
            required
          />
        </Box>
      )}
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="mrp_pack"
          title={getTitles('T-11522')}
          placeHolder={getTitles('T-10360')}
          error={touched.mrp_pack && errors.mrp_pack}
          value={values.mrp_pack}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="unit_price"
          title={getTitles('T-11523')}
          placeHolder={getTitles('T-10360')}
          error={touched.unit_price && errors.unit_price}
          value={values.unit_price}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowDecimal
        />
      </Box>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="free_qty"
          title={getTitles('T-11524')}
          placeHolder={getTitles('T-10360')}
          error={touched.free_qty && errors.free_qty}
          value={values.free_qty}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowOnlyNumbers
        />

        <UITextInputWithTitle
          name="discount"
          title={getTitles('T-11752')}
          placeHolder={getTitles('T-11537')}
          error={touched.discount && errors.discount}
          value={values.discount}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowDecimal
        />
      </Box>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="shipping"
          title={getTitles('T-11530')}
          placeHolder={getTitles('T-10360')}
          error={touched.shipping && errors.shipping}
          value={values.shipping}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="total_unit"
          title={getTitles('T-11527')}
          placeHolder={getTitles('T-10360')}
          error={touched.total_unit && errors.total_unit}
          value={values.total_unit}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.imgConWidth}
          required
          allowDecimal
        />
      </Box>
      <Box sx={styles.nameCont}>
        <UISelect
          name="mark_as"
          title={getTitles('T-11570')}
          options={defaultValues.markAs}
          onChange={handleChange}
          value={values.mark_as}
          error={touched.mark_as && errors.mark_as}
          required
          onBlur={handleBlur}
          readOnly={activePage === view}
          placeholder={getTitles('T-10350')}
          width={styles.imgConWidth}
        />
        <UITitleWithContent
          title={getTitles('T-11517')}
          containerStyle={{ width: styles.imgConWidth }}
          required>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-11421')}
              checked={values.assign_to === 1}
              onClick={() => handleChange({ target: { name: 'assign_to', value: 1 } })}
              disabled={activePage === view}
            />
            <UIRadioButton
              title={getTitles('T-11163')}
              checked={values.assign_to === 2}
              onClick={() => handleChange({ target: { name: 'assign_to', value: 2 } })}
              disabled={activePage === view}
            />
          </Box>
        </UITitleWithContent>
      </Box>
      <Box sx={styles.nameCont}>
        {values.assign_to && (
          <>
            <UIAutocompleted
              name={values.assign_to === store ? 'store_id' : 'employee_id'}
              options={
                values.assign_to === store
                  ? storeList.map((el) => ({
                      _id: el._id,
                      name: el.name,
                      store_id: el.store_id
                    }))
                  : employeeList.map((el) => ({
                      _id: el._id,
                      name: el.first_name + '  ' + el.last_name,
                      employee_id: el.employee_id
                    })) || []
              }
              title={values.assign_to === store ? getTitles('T-11419') : getTitles('T-11572')}
              containerStyle={styles.autoCont}
              optionLabel={(item) =>
                item.name
                  ? item.name
                  : item.first_name
                    ? `${item.first_name + ' ' + item.last_name}`
                    : ''
              }
              onTyping={(val) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (val.length > 2 || val.length === 0) {
                    searchList(val || null, values.assign_to === store ? store : employee);
                  }
                }, 500);
              }}
              onBlur={handleBlur}
              placeHolder={getTitles('T-10077')}
              selectedValue={(val) => {
                setFieldValue(
                  values.assign_to === store ? 'store_id' : 'employee_id',
                  val ? val : { _id: '' }
                );
                setFieldValue(values.assign_to === store ? 'employee_id' : 'store_id', '');
                setBtndisabled(false);
              }}
              preSelectedValue={
                values.assign_to === store
                  ? values.store_id?._id && values.store_id
                  : values.employee_id?._id && values.employee_id
              }
              error={
                values.assign_to === store
                  ? touched.store_id && errors.store_id?._id
                  : touched.employee_id && errors.employee_id?._id
              }
              readOnly={activePage === view}
              titleStyle={styles.autoTitle}
              required
              width={styles.imgConWidth}
            />
            <UITextInputWithTitle
              title={getTitles('T-10751')}
              value={
                values.assign_to === store
                  ? values.store_id?.store_id || ''
                  : values.employee_id?.employee_id || ''
              }
              readOnly
              width={styles.imgConWidth}
            />
          </>
        )}
      </Box>
      <Box sx={styles.checkBoxCont}>
        <UICheckBox
          title={getTitles('T-11571')}
          checked={values.free_for_employee}
          containerStyle={styles.checkBox}
          disabled={activePage === view}
          onChange={() =>
            handleChange({
              target: { name: 'free_for_employee', value: !values.free_for_employee }
            })
          }
        />

        <UICheckBox
          title={getTitles('T-11647')}
          checked={values.is_featured}
          containerStyle={styles.checkBox}
          disabled={activePage === view}
          onChange={() =>
            handleChange({
              target: { name: 'is_featured', value: !values.is_featured }
            })
          }
        />
      </Box>
      {activePage !== view && (
        <ModalButtons
          secondaryBtnTitle={getTitles('T-10340')}
          onPrimaryBtnClick={handleSubmit}
          btnPositions="start"
          primaryBtnTitle={
            activePage === add
              ? getTitles('T-10394')
              : activePage === edit
                ? getTitles('T-10046')
                : getTitles('T-11103')
          }
          disabled={activePage !== view && (!isValid || !dirty || btnDisabled)}
          containerStyle={globalStyles.mt(3)}
        />
      )}
    </Box>
  );
};
