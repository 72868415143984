import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../../constants';
import {
  getDetailsPg,
  getInventoryMaterialGroup,
  getInwardActivePage,
  getInwardActiveStep
} from '../../../../services/redux/selectors';
import { getTitles } from '../../../../utils';
import { useEffect } from 'react';
import {
  getProductRequest,
  getVenderRequest,
  setInwardActivePage,
  updateDetailsPg
} from '../../../../services/redux/slices';
import { Box } from '@mui/material';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { EditButton, UIButton } from '../../../../ui-kits';
import { styles } from './styles';
import { InwardVendorDetails } from '../inward-details';
import { getProductList, getVender } from '../../../../services/redux/selectors/manageProccess';

export const AddEditInward = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const detailsPg = useSelector(getDetailsPg);
  const activeStep = useSelector(getInwardActiveStep);
  const activePage = useSelector(getInwardActivePage);
  const productList = useSelector(getProductList);
  const vendor = useSelector(getVender);
  const materialGroup = useSelector(getInventoryMaterialGroup);

  const { edit, view } = defaultValues.actionType;

  useEffect(() => {
    dispatch(
      getProductRequest({
        filter: JSON.stringify([{ is_active: true }, { material_group: materialGroup }])
      })
    );

    dispatch(getVenderRequest({ page: 1, filter: JSON.stringify([{ is_active: true }]) }));
  }, []);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  const handleBackButton = () => {
    if (activeStep === 0 && activePage !== 1) {
      dispatch(setInwardActivePage(null));
      dispatch(updateDetailsPg(false));
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.stepperCont}>
        {!defaultValues.isResponsive && <UIBackButton onClick={handleBackButton} />}

        <UIButton
          title={!defaultValues.isResponsive && getTitles('T-10084')}
          startIcon={<EditButton iconStyle={styles.editIcon} />}
          style={styles.editBtn(activePage === view && !isReadOnly)}
          onClick={() => dispatch(setInwardActivePage(edit))}
        />
      </Box>

      <InwardVendorDetails
        vendor={vendor}
        materialGroup={materialGroup}
        productList={productList}
      />
    </Box>
  );
};
