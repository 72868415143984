import { Box } from '@mui/material';
import { styles } from './styles';
import { NoData, Text, UIButton } from '../../../../ui-kits';
import { convertToTitleCase, getTitles, resetMessages } from '../../../../utils';
import { ShoppingCartCard } from '../../../common';
import {
  authStatus,
  getCartItems,
  getEcommerceErrorMsg,
  getEcommerceSuccessMsg,
  getScroll,
  getUserData
} from '../../../../services/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetECommerceMsg,
  setCartItems,
  setIsEcommerce,
  updateCartItemsRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { useEffect, useMemo } from 'react';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector(getCartItems);
  const userData = useSelector(getUserData);
  const isAuthenticated = useSelector(authStatus);
  const scroll = useSelector(getScroll);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const errorMsg = useSelector(getEcommerceErrorMsg);

  const cartList = Object.values(cartItems || {});
  const billingAddress = userData?.address;
  const shippingAddress = userData?.shipping_address?.length > 0 && userData?.shipping_address[0];
  const res = defaultValues.isResponsive;

  useEffect(() => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  const getQuantity = () => {
    let qty = 0;
    cartList?.map((item) => (qty = qty + item.quantity));
    return qty;
  };
  const totalQuantity = getQuantity();

  const totalPrice = useMemo(() => {
    let price = 0;
    cartList?.map((item) => {
      if (item.free_for_employee && isAuthenticated && [1, 2].includes(userData?.user_role)) {
        price = price + 0;
      } else {
        price = price + item.final_price * item.quantity;
      }
    });
    return price;
  }, [totalQuantity]);

  const handleMinusQty = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({
          product_id: item.product_id,
          quantity: cartItems[item.product_id]?.quantity - 1
        })
      );
    } else {
      let final = { ...cartItems };
      if (cartItems[item._id].quantity > 1) {
        final = { ...final, [item._id]: { ...item, quantity: cartItems[item._id].quantity - 1 } };
      } else {
        delete final[item._id];
      }
      dispatch(setCartItems(final));
    }
  };

  const handleAddCartQuatity = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({
          product_id: item.product_id,
          quantity: cartItems[item.product_id].quantity + 1
        })
      );
    } else {
      dispatch(
        setCartItems({
          ...cartItems,
          [item._id]: { ...item, quantity: cartItems[item._id].quantity + 1 }
        })
      );
    }
  };

  const handleCloseButtonClick = (item) => {
    if (isAuthenticated) {
      dispatch(updateCartItemsRequest({ product_id: item.product_id, quantity: 0 }));
    } else {
      let final = { ...cartItems };
      delete final[item._id];
      dispatch(setCartItems(final));
    }
  };

  const handleCheckout = () => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.CHECKOUT}`);
  };
  const Address = ({ title, address }) => {
    return (
      <Box sx={styles.addressCont}>
        <Text variant="BoldH20" style={styles.addressTitle}>
          {title}
        </Text>
        <Text variant="D20" style={styles.addressText}>
          {address}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
      {!res && <Text variant="boldH32">{getTitles('T-11663')}</Text>}
      {isAuthenticated && userData && (
        <Box sx={styles.addressSuperCont}>
          <Address
            title={`${getTitles('T-11659')} :`}
            address={
              [
                billingAddress?.address_line_one,
                billingAddress?.address_line_two,
                billingAddress?.state_id?.name,
                billingAddress?.district_id?.name,
                billingAddress?.taluka_id?.name,
                billingAddress?.village_id?.name,
                billingAddress?.post_code
              ]
                .filter((item) => ![null, undefined, ''].includes(item))
                .join(', ') || getTitles('T-11061')
            }
          />
          <Address
            title={`${getTitles('T-11660')} :`}
            address={
              [
                shippingAddress?.address_line_one,
                shippingAddress?.address_line_two,
                shippingAddress?.state_id?.name,
                shippingAddress?.district_id?.name,
                shippingAddress?.taluka_id?.name,
                shippingAddress?.village_id?.name,
                shippingAddress?.post_code
              ]
                .filter((item) => ![null, undefined, ''].includes(item))
                .join(', ') || getTitles('T-11061')
            }
          />
        </Box>
      )}
      <Box sx={styles.subContainer}>
        {cartList.length > 0 ? (
          cartList.map((elem, idx) => (
            <ShoppingCartCard
              key={idx}
              count={elem.quantity}
              productName={elem.product_name}
              image={elem.product_image && elem.product_image[0]}
              price={elem.free_for_employee && isAuthenticated ? 0 : elem.final_price || ''}
              totalPrice={
                elem.free_for_employee && isAuthenticated ? 0 : elem.final_price * elem.quantity
              }
              addQty={() => handleAddCartQuatity(elem)}
              minusQty={() => handleMinusQty(elem)}
              onCloseButtonClick={() => handleCloseButtonClick(elem)}
              materialGroup={convertToTitleCase(elem.material_group?.name)}
              item={elem}
            />
          ))
        ) : (
          <NoData message={getErrorMessage('E-10028')} />
        )}
      </Box>

      {cartList.length > 0 && (
        <Box sx={styles.subSuperCont}>
          <Box sx={styles.subTotalCont}>
            <Text variant={res ? 'BoldH14' : 'D30'}>
              {res ? `${getTitles('T-11664').toUpperCase()}` : `${getTitles('T-11664')} : `}
            </Text>
            <Text variant="D22" style={styles.totalPrice}>{`₹ ${totalPrice}`}</Text>
          </Box>
          <UIButton
            title={getTitles('T-11718').toUpperCase()}
            style={styles.buynowBtn}
            onClick={handleCheckout}
          />
        </Box>
      )}
    </Box>
  );
};
