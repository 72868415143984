import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
export const utilStyles = {
  basicCont: (style) => ({
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    ...style
  }),
  text: (onTitleClick, style) => ({
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: onTitleClick ? 'pointer' : 'default',
    textDecoration: 'none',
    ...style
  }),
  titleText: (onTitleClick, titleStyle) => ({
    textDecoration: onTitleClick ? 'underline' : 'none',
    ...titleStyle
  }),
  phoneText: {
    textDecoration: 'none'
  },
  profileIcon: (style) => ({
    width: '30px',
    height: '30px',
    ...style
  }),
  basicSubTitle: (style) => ({
    color: color.secondaryText,
    ...style
  }),
  titleCont: (isicon) => ({
    width: isicon ? 'calc(100% - 40px)' : '100%'
  }),
  descContainer: (style) => ({
    width: '100%',
    ...style
  }),

  descVal: {
    color: color.secondaryText
  },
  cartCont: (style) => ({
    width: 'fit-content',
    padding: sm ? '1px 5px' : '2px 10px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    backgroundColor: color.primary,
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    height: res ? '30px' : '35px',
    ...style
  }),
  cartCountText: (style) => ({
    width: sm ? '30px' : '50px',
    textAlign: 'center',
    color: color.tertiaryText,
    ...style
  }),
  cartBtn: (style, disabled) => ({
    cursor: disabled ? 'default' : 'pointer',
    fontSize: sm ? '15px' : res ? '20px' : '25px',
    color: color.tertiaryText,
    opacity: disabled ? '0.2' : '1',
    ...style
  })
};
