import { Box } from '@mui/material';
import { styles } from './styles';
import {
  equipmentCatSchema,
  getTitles,
  inwardCommonSchema,
  inwardMedicinesSchema,
  inwardMGCommonOrOtherSchema,
  inwardProDetDefaultSchema,
  inwardSemenCowSchema,
  isImageFormat,
  isImageUrl,
  isVideoUrl,
  objectToFormData,
  removeFalsyValues
} from '../../../../utils';
import { useFormik } from 'formik';
import {
  DatePickers,
  MultiImage,
  UIAutocompleted,
  UICheckBox,
  UIRadioButton,
  UISelect,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInventoryMaterialGroup,
  getInventorySuccessMsg,
  getInwardActivePage,
  getInwardEmployees,
  getProductDetails,
  getScroll
} from '../../../../services/redux/selectors';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';
import {
  getInwardEmployeeRequest,
  getProductRequest,
  getStoreRequest,
  setProductDetails,
  updateInventoryProductRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { getStore } from '../../../../services/redux/selectors/manageProccess';
import dayjs from 'dayjs';

export const InwardProductDetails = ({
  productList,
  commonCategory,
  cowBreed,
  cowVariety,
  buffaloBreed,
  onNext,
  onPrev
}) => {
  const dispatch = useDispatch();
  const activePage = useSelector(getInwardActivePage);
  const employeeList = useSelector(getInwardEmployees);
  const storeList = useSelector(getStore);
  const successMsg = useSelector(getInventorySuccessMsg);
  const productData = useSelector(getProductDetails);
  const materialGroup = useSelector(getInventoryMaterialGroup);
  const scroll = useSelector(getScroll);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [proDetails, setProDetails] = useState(null);
  const [schema, setSchema] = useState(inwardProDetDefaultSchema);
  let timer;
  const { view, edit, add } = defaultValues.actionType;
  const {
    semenCategory,
    animalCategory,
    surgicalInstumCategory,
    fodderSeedsCategory,
    equipOrAccessCategory,
    consumablesCategory
  } = defaultValues.inwardCategory;

  const {
    semenStraw,
    equipment,
    medicines,
    consumables,
    animalFeedSupplements,
    feedFodder,
    fodderSeeds,
    accessories,
    veterinarySurgicalInstruments,
    commonOrOther
  } = defaultValues.inventoryMaterialGroupId;

  const { store, employee } = defaultValues.inwardAssignTo;
  const { productMaster } = defaultValues.appLogicalTexts;
  const { vetDoctor, aiWorker, laboratoryIncharge } = defaultValues.employeeRol;

  const { cow } = defaultValues.inwardCategoryVal.semenCategoryVal;

  const {
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    setFieldTouched,
    setValues,
    setFieldValue,
    validateForm,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      product_master_id: { _id: '' },
      product_image: [],
      category_enum: '',
      category_id: '',
      breed_id: '',
      variety_id: '',
      type: '',
      affiliation: '',
      semen_station: '',
      producer_first_name: '',
      producer_middle_name: '',
      producer_last_name: '',
      iso_certified: null,
      bull_number: '',
      bull_name: '',
      bull_type: '',
      bull_image: [],
      dob: null,
      inaph_id: '',
      dose: '',
      year_production: null,
      sire: '',
      dam_yield: '',
      fat_per: '',
      fat_kg: '',
      sir_dam_yield_kg: '',
      parents_avg: '',
      hsn: '',
      brand_name: '',
      return_policy: '',
      refrigeration_required: null,
      color: '',
      power: '',
      product_type: '',
      assign_to: null,
      store_id: { _id: '' },
      employee_id: { _id: '' },
      mark_as: '',
      free_for_employee: false,
      is_featured: false
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: schema
  });

  useEffect(() => {
    dispatch(updateScrollToTop(!scroll));
  }, []);

  useEffect(() => {
    if (['S-10119', 'S-10120'].includes(successMsg)) {
      onNext();
    }
  }, [successMsg]);

  useEffect(() => {
    setProDetails(productData);
  }, [productData]);

  useEffect(() => {
    if (proDetails?.product_details) {
      let proImages = [...proDetails.product_details.product_image];
      if (proDetails.product_details?.product_video) {
        proImages = [...proImages, ...proDetails.product_details.product_video];
      }

      const newData = {
        ...proDetails.product_details,
        bull_image: proDetails.product_details?.bull_image
          ? [
              typeof proDetails.product_details?.bull_image === 'string'
                ? `${proDetails.product_details?.bull_image}?timestamp=${new Date().getTime()}`
                : proDetails.product_details?.bull_image
            ]
          : [],

        product_image: proImages,
        employee_id: proDetails.product_details.employee_id || { _id: '' },
        store_id: proDetails.product_details.store_id || { _id: '' },
        year_production:
          proDetails.product_details.year_production &&
          dayjs(proDetails.product_details.year_production),
        dob: proDetails.product_details.dob && dayjs(proDetails.product_details.dob),
        free_for_employee: proDetails.product_details.free_for_employee || false,
        is_featured: proDetails.product_details?.is_featured || false,
        mark_as: productData.product_details?.mark_as?.id || productData.product_details?.mark_as,
        category_enum:
          productData.product_details?.category_enum?.id ||
          productData.product_details.category_enum,

        category_id:
          productData.product_details?.category_id?._id || productData.product_details.category_id,

        breed_id:
          productData.product_details?.breed_id?._id || productData.product_details?.breed_id,
        variety_id:
          productData.product_details?.variety_id?._id ||
          productData.product_details?.variety_id ||
          '',
        affiliation:
          productData.product_details?.affiliation?.id ||
          productData.product_details?.affiliation ||
          '',
        bull_type:
          productData.product_details?.bull_type?.id || productData.product_details?.bull_type,
        semen_station:
          productData.product_details?.semen_station?.id ||
          productData.product_details?.semen_station ||
          '',
        assign_to:
          productData.product_details?.assign_to?.id || productData.product_details?.assign_to,
        type: productData.product_details?.type?.id || productData.product_details?.type
      };

      setValues(newData);
    }
  }, [proDetails, setValues]);

  useEffect(() => {
    if (values.product_master_id?.material_group?.id === semenStraw) setCategoryList(semenCategory);
    if (
      [medicines, animalFeedSupplements, feedFodder].includes(
        values.product_master_id?.material_group?.id
      )
    )
      setCategoryList(animalCategory);
    if ([equipment, accessories].includes(values.product_master_id?.material_group?.id)) {
      setCategoryList(equipOrAccessCategory);
    }
    if ([consumables].includes(values.product_master_id?.material_group?.id))
      setCategoryList(consumablesCategory);

    if ([fodderSeeds].includes(values.product_master_id?.material_group?.id))
      setCategoryList(fodderSeedsCategory);

    if ([veterinarySurgicalInstruments].includes(values.product_master_id?.material_group?.id))
      setCategoryList(surgicalInstumCategory);
    if ([commonOrOther].includes(values.product_master_id?.material_group?.id))
      setCategoryList(commonCategory);

    if (
      [
        consumables,
        animalFeedSupplements,
        feedFodder,
        fodderSeeds,
        accessories,
        veterinarySurgicalInstruments
      ].includes(values.product_master_id?.material_group?.id)
    ) {
      setSchema(inwardCommonSchema);
    }

    if (values.product_master_id?.material_group?.id === commonOrOther) {
      setSchema(inwardMGCommonOrOtherSchema);
    }

    if (values.product_master_id?.material_group?.id === medicines) {
      setSchema(inwardMedicinesSchema);
    }

    if (values.product_master_id?.material_group?.id === equipment) {
      setSchema(equipmentCatSchema);
    }

    if (values.product_master_id?.material_group?.id === semenStraw) {
      setSchema(inwardSemenCowSchema);
    }
  }, [values.product_master_id?.material_group?.id]);

  useEffect(() => {
    setValues({ ...values });
  }, [schema, values.store_id, values.employee_id]);

  useEffect(() => {
    values.assign_to === store &&
      dispatch(getStoreRequest({ filter: JSON.stringify([{ is_active: true }]) }));
    values.assign_to === employee &&
      dispatch(
        getInwardEmployeeRequest({
          employee_permission_list: JSON.stringify([vetDoctor, aiWorker, laboratoryIncharge])
        })
      );
  }, [values.assign_to]);

  useEffect(() => {
    !btnDisabled &&
      setBtndisabled(!isImageFormat(values.product_image?.length > 0 ? values.product_image : []));
  }, [values.product_image?.length]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
    if (name === 'assign_to') {
      if (value === 1) setFieldValue('employee_id', { _id: '' });
      if (value === 2) setFieldValue('store_id', { _id: '' });
      validateForm();
    }
    if (name === 'category_enum') {
      if (value === 2) setFieldValue('variety_id', '');
    }
  };

  const searchList = (val, type) => {
    if (type === productMaster)
      dispatch(
        getProductRequest({
          search_text: val || null,
          filter: JSON.stringify([{ is_active: true }, { material_group: materialGroup }])
        })
      );
    if (type === store) dispatch(getStoreRequest({ search_text: val }));
    if (type === employee)
      dispatch(
        getInwardEmployeeRequest({
          search_text: val,
          employee_permission_list: JSON.stringify([vetDoctor, aiWorker, laboratoryIncharge])
        })
      );
  };

  const handleSubmit = () => {
    let payload = {
      _id: productData?._id,
      product_details: {
        ...values,
        bull_image: values.bull_image?.length > 0 ? values.bull_image[0] : null
      }
    };

    payload.product_details = removeFalsyValues(payload.product_details);
    payload = removeFalsyValues(payload);
    activePage === add && dispatch(setProductDetails(payload));
    if (activePage === edit) {
      payload.product_details = {
        ...payload.product_details,
        product_master_id: values.product_master_id?._id,
        store_id: values.store_id?._id || null,
        employee_id: values.employee_id?._id || null,
        product_image:
          values.product_image?.length > 0 &&
          values.product_image
            ?.map((el) => (el?.type?.startsWith('image/') || isImageUrl(el) ? el : null))
            ?.filter((ite) => ![null, undefined, ''].includes(ite)),
        product_video: values.product_image
          ?.map((el) => (el?.type?.startsWith('video/') || isVideoUrl(el) ? el : null))
          ?.filter((ite) => ![null, undefined, ''].includes(ite))
      };
      payload.product_details = removeFalsyValues(payload.product_details);
      dispatch(updateInventoryProductRequest(objectToFormData(payload)));
    }
    [add, view].includes(activePage) && onNext();
  };
  const handleBackButton = () => {
    onPrev();
  };

  const handleImagesChange = (key) => (newImages) => {
    setFieldValue(key, newImages);
    setBtndisabled(false);
  };

  const handleDelete = (type, img) => {
    setFieldValue(type, img);
    setBtndisabled(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.imgSuperCont}>
        <Box sx={styles.imgLeftCont}>
          <UIAutocompleted
            name="product_master_id"
            required={true}
            options={productList}
            title={getTitles('T-11437')}
            containerStyle={styles.autoCont}
            optionLabel={(item) => (item.name ? item.name : '')}
            onTyping={(val) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                if (val.length > 2 || val.length === 0) {
                  searchList(val, productMaster);
                }
              }, 500);
            }}
            onBlur={handleBlur}
            placeHolder={getTitles('T-10077')}
            selectedValue={(val) => {
              resetForm();
              setFieldValue('product_master_id', val ? val : { _id: '' });

              setBtndisabled(false);
            }}
            preSelectedValue={values.product_master_id?._id && values.product_master_id}
            error={touched.product_master_id && errors.product_master_id?._id}
            readOnly={activePage === view}
            titleStyle={styles.autoTitle}
            width={styles.imgConWidth}
          />
          {values.product_master_id?._id && (
            <UITextInputWithTitle
              name="product_id"
              title={getTitles('T-11457')}
              placeHolder={getTitles('T-10384')}
              value={values.product_master_id?.product_id}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly
              width={styles.imgConWidth}
            />
          )}

          {values.product_master_id?._id && (
            <>
              <UITextInputWithTitle
                name="material_group"
                title={getTitles('T-10180')}
                placeHolder={getTitles('T-10384')}
                value={values.product_master_id?.material_group?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                width={styles.imgConWidth}
                readOnly
              />
              <UISelect
                name={
                  values.product_master_id?.material_group?.id === commonOrOther
                    ? 'category_id'
                    : 'category_enum'
                }
                title={getTitles('T-11471')}
                options={categoryList}
                returnValue={
                  values.product_master_id?.material_group?.id === commonOrOther ? '_id' : 'value'
                }
                optionLabel={
                  values.product_master_id?.material_group?.id === commonOrOther ? 'name' : 'title'
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={
                  values.product_master_id?.material_group?.id === commonOrOther
                    ? values.category_id
                    : values.category_enum || ''
                }
                readOnly={activePage === view}
                error={
                  values.product_master_id?.material_group?.id === commonOrOther
                    ? touched.category_id && errors.category_id
                    : touched.category_enum && errors.category_enum
                }
                placeholder={getTitles('T-10350')}
                width={styles.imgConWidth}
                required
              />

              {values.product_master_id?.material_group?.id === semenStraw && (
                <>
                  <UISelect
                    name="breed_id"
                    title={getTitles('T-11473')}
                    options={values.category_enum === cow ? cowBreed : buffaloBreed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.breed_id}
                    optionLabel="name"
                    returnValue="_id"
                    error={touched.breed_id && errors.breed_id}
                    readOnly={activePage === view}
                    placeholder={getTitles('T-10350')}
                    width={styles.imgConWidth}
                    required
                  />
                  {values.product_master_id?.material_group?.id === semenStraw &&
                    values.category_enum === cow && (
                      <UISelect
                        name="variety_id"
                        title={getTitles('T-11474')}
                        options={cowVariety}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        optionLabel="name"
                        returnValue="_id"
                        value={values.variety_id}
                        error={touched.variety_id && errors.variety_id}
                        readOnly={activePage === view}
                        placeholder={getTitles('T-10350')}
                        width={styles.imgConWidth}
                        required
                      />
                    )}
                </>
              )}
              {(values.product_master_id?.material_group?.id === equipment ||
                [
                  medicines,
                  consumables,
                  animalFeedSupplements,
                  feedFodder,
                  fodderSeeds,
                  veterinarySurgicalInstruments,
                  commonOrOther,
                  accessories
                ].includes(values.product_master_id?.material_group?.id)) && (
                <UITextInputWithTitle
                  name="brand_name"
                  title={getTitles('T-11494')}
                  placeHolder={getTitles('T-10384')}
                  value={values.brand_name}
                  error={touched.brand_name && errors.brand_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width={styles.imgConWidth}
                  readOnly={activePage === view}
                  required
                />
              )}

              {[medicines].includes(values.product_master_id?.material_group?.id) && (
                <UITextInputWithTitle
                  name="return_policy"
                  title={getTitles('T-11533')}
                  placeHolder={getTitles('T-10384')}
                  value={values.return_policy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.return_policy && errors.return_policy}
                  readOnly={activePage === view}
                  width={styles.imgConSecWidth}
                  required
                />
              )}

              {[
                animalFeedSupplements,
                consumables,
                feedFodder,
                fodderSeeds,
                veterinarySurgicalInstruments,
                commonOrOther,
                accessories
              ].includes(values.product_master_id?.material_group?.id) && (
                <UITextInputWithTitle
                  name="product_type"
                  title={getTitles('T-11472')}
                  placeHolder={getTitles('T-10384')}
                  value={values.product_type}
                  error={touched.product_type && errors.product_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width={styles.imgConWidth}
                  readOnly={activePage === view}
                  required
                />
              )}

              {values.product_master_id?.material_group?.id === equipment && (
                <UISelect
                  name="product_type"
                  title={getTitles('T-11472')}
                  options={defaultValues.equipmentType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.product_type}
                  error={touched.product_type && errors.product_type}
                  readOnly={activePage === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.imgConWidth}
                  required
                />
              )}
            </>
          )}
        </Box>
        {values.product_master_id?._id && (
          <Box sx={styles.imgRightCont}>
            <MultiImage
              title={getTitles('T-11501')}
              images={values.product_image}
              onImagesChange={handleImagesChange('product_image')}
              prevImg={values.product_image}
              disabled={activePage === view}
              variant="D20"
              imageToDelete={
                activePage !== add ? (val) => handleDelete('product_image', val) : null
              }
              containerStyle={styles.imageCont}
              required
              accept={'image/*,video/*'}
            />
          </Box>
        )}
      </Box>

      {values.product_master_id?._id && (
        <>
          <Box sx={styles.gridCont}>
            {[
              consumables,
              animalFeedSupplements,
              feedFodder,
              fodderSeeds,
              veterinarySurgicalInstruments,
              commonOrOther,
              accessories
            ].includes(values.product_master_id?.material_group?.id) && (
              <>
                <UITextInputWithTitle
                  name="color"
                  title={getTitles('T-10178')}
                  placeHolder={getTitles('T-10586')}
                  value={values.color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.color && errors.color}
                  readOnly={activePage === view}
                  width={styles.width}
                  allowOnlyAlphabets={true}
                  required
                />
                <UITextInputWithTitle
                  name="power"
                  title={getTitles('T-11532')}
                  placeHolder={getTitles('T-10586')}
                  value={values.power}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.power && errors.power}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                />
                <UITextInputWithTitle
                  name="return_policy"
                  title={getTitles('T-11533')}
                  placeHolder={getTitles('T-10384')}
                  value={values.return_policy}
                  error={touched.return_policy && errors.return_policy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.tertiaryWidth}
                  required
                />
              </>
            )}

            {values.product_master_id?.material_group?.id === semenStraw && (
              <>
                <UISelect
                  name="type"
                  title={getTitles('T-10088')}
                  options={defaultValues.semenType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.type}
                  error={touched.type && errors.type}
                  readOnly={activePage === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.width}
                  required
                />
                <UISelect
                  name="affiliation"
                  title={getTitles('T-11499')}
                  options={defaultValues.affiliation}
                  onChange={handleChange}
                  value={values.affiliation}
                  readOnly={activePage === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.width}
                />
                <UISelect
                  title={getTitles('T-11500')}
                  options={defaultValues.accreditionType}
                  name="semen_station"
                  onChange={handleChange}
                  value={values.semen_station}
                  readOnly={activePage === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.tertiaryWidth}
                />
              </>
            )}

            {values.product_master_id?.material_group?.id === semenStraw && (
              <>
                <UITextInputWithTitle
                  name="producer_first_name"
                  title={getTitles('T-11502')}
                  placeHolder={getTitles('T-10047')}
                  error={touched.producer_first_name && errors.producer_first_name}
                  value={values.producer_first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                />
                <UITextInputWithTitle
                  name="producer_middle_name"
                  title={getTitles('T-10337')}
                  placeHolder={getTitles('T-10338')}
                  value={values.producer_middle_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                />
                <UITextInputWithTitle
                  name="producer_last_name"
                  title={getTitles('T-10042')}
                  placeHolder={getTitles('T-10048')}
                  error={touched.producer_last_name && errors.producer_last_name}
                  value={values.producer_last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.tertiaryWidth}
                  required
                />
              </>
            )}
          </Box>
          {values.product_master_id?.material_group?.id === semenStraw && (
            <Box sx={styles.imgSuperCont}>
              <Box sx={styles.imgLeftCont}>
                <UITitleWithContent
                  title={getTitles('T-11504')}
                  containerStyle={styles.radioCont}
                  required>
                  <Box sx={styles.radioSubCont}>
                    <UIRadioButton
                      title={getTitles('T-10705')}
                      checked={values.iso_certified}
                      onClick={() =>
                        handleChange({ target: { name: 'iso_certified', value: true } })
                      }
                      disabled={activePage === view}
                    />
                    <UIRadioButton
                      title={getTitles('T-10706')}
                      checked={values.iso_certified === false}
                      onClick={() =>
                        handleChange({ target: { name: 'iso_certified', value: false } })
                      }
                      disabled={activePage === view}
                    />
                  </Box>
                </UITitleWithContent>
                <UITextInputWithTitle
                  name="bull_number"
                  title={getTitles('T-11505')}
                  placeHolder={getTitles('T-10586')}
                  error={touched.bull_number && errors.bull_number}
                  value={values.bull_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.imgConWidth}
                  required
                />

                <UITextInputWithTitle
                  name="bull_name"
                  title={getTitles('T-11507')}
                  placeHolder={getTitles('T-10384')}
                  error={touched.bull_name && errors.bull_name}
                  value={values.bull_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  allowOnlyAlphabets={true}
                  readOnly={activePage === view}
                  width={styles.imgConWidth}
                  required
                />
                <UISelect
                  title={getTitles('T-11478')}
                  options={defaultValues.bullType}
                  name="bull_type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bull_type}
                  error={touched.bull_type && errors.bull_type}
                  readOnly={activePage === view}
                  placeholder={getTitles('T-10350')}
                  width={styles.imgConWidth}
                  required
                />

                <DatePickers
                  dateValue={values.dob}
                  onDateChange={(val) => handleChange({ target: { value: val, name: 'dob' } })}
                  title={getTitles('T-10344')}
                  width={styles.imgConWidth}
                  error={touched.dob && errors.dob}
                  onOpen={() => setFieldTouched('dob', true)}
                  required
                  disableFuture
                />

                <UITextInputWithTitle
                  name="inaph_id"
                  title={getTitles('T-11508')}
                  placeHolder={getTitles('T-11538')}
                  error={touched.inaph_id && errors.inaph_id}
                  value={values.inaph_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.imgConSecWidth}
                  required
                />
              </Box>
              <Box sx={styles.imgRightCont}>
                <MultiImage
                  title={getTitles('T-11506')}
                  images={values.bull_image}
                  onImagesChange={handleImagesChange('bull_image')}
                  prevImg={values.bull_image}
                  disabled={activePage === view}
                  variant="D20"
                  imageToDelete={
                    activePage !== add ? (val) => handleDelete('bull_image', val) : null
                  }
                  containerStyle={styles.imageCont}
                  showAdd={false}
                  showBubble={false}
                  required
                />
              </Box>
            </Box>
          )}

          <Box sx={styles.gridCont}>
            {values.product_master_id?.material_group?.id === semenStraw && (
              <>
                <UITextInputWithTitle
                  name="dose"
                  title={getTitles('T-11509')}
                  placeHolder={getTitles('T-10360')}
                  error={touched.dose && errors.dose}
                  value={values.dose}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                  allowOnlyNumbers
                />
                <DatePickers
                  dateValue={values.year_production}
                  onDateChange={(val) =>
                    handleChange({ target: { value: val, name: 'year_production' } })
                  }
                  title={getTitles('T-11479')}
                  width={styles.width}
                  required={true}
                  error={touched.year_production && errors.year_production}
                  onOpen={() => setFieldTouched('year_production', true)}
                />
                <UITextInputWithTitle
                  name="sire"
                  title={getTitles('T-11510')}
                  placeHolder={getTitles('T-10586')}
                  value={values.sire}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                />
              </>
            )}

            {values.product_master_id?.material_group?.id === semenStraw && (
              <>
                <UITextInputWithTitle
                  name="dam_yield"
                  title={getTitles('T-11511')}
                  placeHolder={getTitles('T-10360')}
                  error={touched.dam_yield && errors.dam_yield}
                  value={values.dam_yield}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                  allowOnlyNumbers
                />
                <UITextInputWithTitle
                  name="fat_per"
                  title={getTitles('T-11512')}
                  placeHolder={getTitles('T-11536')}
                  error={touched.fat_per && errors.fat_per}
                  value={values.fat_per}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                  allowDecimal
                />
                <UITextInputWithTitle
                  name="fat_kg"
                  title={getTitles('T-11513')}
                  placeHolder={getTitles('T-10360')}
                  error={touched.fat_kg && errors.fat_kg}
                  value={values.fat_kg}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={activePage === view}
                  width={styles.width}
                  required
                  allowOnlyNumbers
                />
              </>
            )}

            <>
              {values.product_master_id?.material_group?.id === semenStraw && (
                <>
                  <UITextInputWithTitle
                    name="sir_dam_yield_kg"
                    title={getTitles('T-11514')}
                    placeHolder={getTitles('T-10360')}
                    value={values.sir_dam_yield_kg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={activePage === view}
                    width={styles.tertiaryWidth}
                    allowOnlyNumbers
                  />
                  <UITextInputWithTitle
                    name="parents_avg"
                    title={getTitles('T-11515')}
                    placeHolder={getTitles('T-10360')}
                    value={values.parents_avg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={activePage === view}
                    width={styles.tertiaryWidth}
                    allowOnlyNumbers
                  />
                </>
              )}

              {values.product_master_id?.material_group?.id === medicines && (
                <UITitleWithContent
                  title={getTitles('T-11534')}
                  containerStyle={{ width: styles.tertiaryWidth }}
                  required>
                  <Box sx={styles.radioSubCont}>
                    <UIRadioButton
                      title={getTitles('T-10705')}
                      checked={values.refrigeration_required}
                      onClick={() =>
                        handleChange({ target: { name: 'refrigeration_required', value: true } })
                      }
                      disabled={activePage === view}
                    />
                    <UIRadioButton
                      title={getTitles('T-10706')}
                      checked={values.refrigeration_required === false}
                      onClick={() =>
                        handleChange({ target: { name: 'refrigeration_required', value: false } })
                      }
                      disabled={activePage === view}
                    />
                  </Box>
                </UITitleWithContent>
              )}

              {values.product_master_id?.material_group?.id === equipment && (
                <>
                  <UITextInputWithTitle
                    name="power"
                    title={getTitles('T-11532')}
                    placeHolder={getTitles('T-10586')}
                    value={values.power}
                    error={touched.power && errors.power}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={activePage === view}
                    width={styles.tertiaryWidth}
                    required
                  />
                  <UITextInputWithTitle
                    name="return_policy"
                    title={getTitles('T-11533')}
                    placeHolder={getTitles('T-10384')}
                    value={values.return_policy}
                    error={touched.return_policy && errors.return_policy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={activePage === view}
                    width={styles.tertiaryWidth}
                    required
                  />
                </>
              )}

              <UITextInputWithTitle
                name="hsn"
                title={getTitles('T-11516')}
                placeHolder={getTitles('T-10642')}
                value={values.hsn}
                error={touched.hsn && errors.hsn}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={activePage === view}
                width={styles.width}
                required
              />

              <UISelect
                name="mark_as"
                title={getTitles('T-11570')}
                options={defaultValues.markAs}
                onChange={handleChange}
                value={values.mark_as}
                error={touched.mark_as && errors.mark_as}
                required
                onBlur={handleBlur}
                readOnly={activePage === view}
                placeholder={getTitles('T-10350')}
                width={styles.width}
              />

              <UITitleWithContent
                title={getTitles('T-11517')}
                containerStyle={{ width: styles.secWidth }}
                required>
                <Box sx={styles.radioSubCont}>
                  <UIRadioButton
                    title={getTitles('T-11421')}
                    checked={values.assign_to === 1}
                    onClick={() => handleChange({ target: { name: 'assign_to', value: 1 } })}
                    disabled={activePage === view}
                  />
                  <UIRadioButton
                    title={getTitles('T-11163')}
                    checked={values.assign_to === 2}
                    onClick={() => handleChange({ target: { name: 'assign_to', value: 2 } })}
                    disabled={activePage === view}
                  />
                </Box>
              </UITitleWithContent>
              {values.assign_to && (
                <>
                  <UIAutocompleted
                    name={values.assign_to === store ? 'store_id' : 'employee_id'}
                    options={
                      values.assign_to === store
                        ? storeList.map((el) => ({
                            _id: el._id,
                            name: el.name,
                            store_id: el.store_id
                          }))
                        : employeeList.map((el) => ({
                            _id: el._id,
                            name: el.first_name + '  ' + el.last_name,
                            employee_id: el.employee_id
                          })) || []
                    }
                    title={values.assign_to === store ? getTitles('T-11419') : getTitles('T-11572')}
                    containerStyle={styles.autoCont}
                    optionLabel={(item) =>
                      item.name
                        ? item.name
                        : item.first_name
                          ? `${item.first_name + ' ' + item.last_name}`
                          : ''
                    }
                    onTyping={(val) => {
                      clearTimeout(timer);
                      timer = setTimeout(() => {
                        if (val.length > 2 || val.length === 0) {
                          searchList(val || null, values.assign_to === store ? store : employee);
                        }
                      }, 500);
                    }}
                    onBlur={handleBlur}
                    placeHolder={getTitles('T-10077')}
                    selectedValue={(val) => {
                      setFieldValue(
                        values.assign_to === store ? 'store_id' : 'employee_id',
                        val ? val : { _id: '' }
                      );
                      setFieldValue(values.assign_to === store ? 'employee_id' : 'store_id', '');
                      setBtndisabled(false);
                    }}
                    preSelectedValue={
                      values.assign_to === store
                        ? values.store_id?._id && values.store_id
                        : values.employee_id?._id && values.employee_id
                    }
                    error={
                      values.assign_to === store
                        ? touched.store_id && errors.store_id?._id
                        : touched.employee_id && errors.employee_id?._id
                    }
                    readOnly={activePage === view}
                    titleStyle={styles.autoTitle}
                    required
                    width={styles.width}
                  />
                  <UITextInputWithTitle
                    title={getTitles('T-10751')}
                    value={
                      values.assign_to === store
                        ? values.store_id?.store_id || ''
                        : values.employee_id?.employee_id || ''
                    }
                    readOnly
                    width={styles.width}
                  />
                </>
              )}
            </>
          </Box>

          <Box sx={styles.checkBoxCont}>
            <UICheckBox
              title={getTitles('T-11571')}
              checked={values.free_for_employee}
              containerStyle={styles.checkBox}
              disabled={activePage === view}
              onChange={() =>
                handleChange({
                  target: { name: 'free_for_employee', value: !values.free_for_employee }
                })
              }
            />

            <UICheckBox
              title={getTitles('T-11647')}
              checked={values.is_featured}
              containerStyle={styles.checkBox}
              disabled={activePage === view}
              onChange={() =>
                handleChange({
                  target: { name: 'is_featured', value: !values.is_featured }
                })
              }
            />
          </Box>
          {defaultValues.isResponsive && values.product_master_id && (
            <Box sx={styles.nameCont}>
              <MultiImage
                title={getTitles('T-11501')}
                images={values.product_image}
                onImagesChange={handleImagesChange('product_image')}
                prevImg={values.product_image}
                disabled={activePage === view}
                variant="D20"
                imageToDelete={
                  activePage !== add ? (val) => handleDelete('product_image', val) : null
                }
                containerStyle={styles.imageCont}
                required
              />

              {values.product_master_id?.material_group?.id === semenStraw && (
                <MultiImage
                  title={getTitles('T-11506')}
                  images={values.bull_image}
                  onImagesChange={handleImagesChange('bull_image')}
                  prevImg={values.bull_image}
                  disabled={activePage === view}
                  variant="D20"
                  imageToDelete={
                    activePage !== add ? (val) => handleDelete('bull_image', val) : null
                  }
                  containerStyle={styles.imageCont}
                  showAdd={false}
                  showBubble={false}
                  required
                />
              )}
            </Box>
          )}

          <ModalButtons
            secondaryBtnTitle={getTitles('T-10045')}
            onSecondaryBtnClick={handleBackButton}
            onPrimaryBtnClick={handleSubmit}
            primaryBtnTitle={
              activePage === add
                ? getTitles('T-10804')
                : activePage === edit
                  ? getTitles('T-10733')
                  : getTitles('T-10804')
            }
            disabled={activePage !== view && (!isValid || !dirty || btnDisabled)}
            containerStyle={globalStyles.mt(3)}
          />
        </>
      )}
    </Box>
  );
};
