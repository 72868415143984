import { Avatar, Box } from '@mui/material';
import { Text } from '../../../ui-kits';
import { utilStyles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const BasicProfile = ({
  url,
  textVariant = 'D20',
  title,
  phone,
  isIcon = true,
  subTitleVariant = 'D16',
  subTitle,
  onTitleClick,
  containerStyle,
  iconPositon = 'left',
  subTitleStyle,
  titleStyle,
  startIconStyle,
  customRightIcon
}) => (
  <Box sx={utilStyles.basicCont(containerStyle)}>
    {isIcon && iconPositon === 'left' && (
      <Avatar alt="" src={url} sx={utilStyles.profileIcon(startIconStyle)} />
    )}
    <Box sx={utilStyles.titleCont(isIcon)}>
      {title && (
        <Text
          variant={textVariant}
          style={utilStyles.text(onTitleClick, titleStyle)}
          onClick={onTitleClick}>
          <span style={utilStyles.titleText(onTitleClick)}>{title}</span>
          {phone && <span style={utilStyles.phoneText}> {phone}</span>}
        </Text>
      )}
      {subTitle && (
        <Text variant={subTitleVariant} style={utilStyles.basicSubTitle(subTitleStyle)}>
          {subTitle}
        </Text>
      )}
    </Box>
    {isIcon && iconPositon === 'right' && !customRightIcon && (
      <Avatar alt="" src={url} sx={utilStyles.profileIcon()} />
    )}
    {isIcon && iconPositon === 'right' && customRightIcon}
  </Box>
);

export const DescTitle = ({ title1, title2, containerStyles }) => {
  return (
    <Box sx={utilStyles.descContainer(containerStyles)}>
      <Text variant="D20">{title1} :</Text>
      <Text variant="BoldH20" style={utilStyles.descVal}>
        {title2}
      </Text>
    </Box>
  );
};

export const CartCounter = ({
  count,
  onAddClick = () => {},
  onMinusClick = () => {},
  containerStyles,
  cartBtnStyle,
  countStyle,
  disabled
}) => {
  const handleAddItems = () => {
    if (!disabled) {
      onAddClick();
    }
  };
  return (
    <Box sx={utilStyles.cartCont(containerStyles)}>
      <RemoveIcon onClick={onMinusClick} sx={utilStyles.cartBtn(cartBtnStyle)} />
      <Text variant={'D18'} style={utilStyles.cartCountText(countStyle)}>
        {count}
      </Text>
      <AddIcon onClick={handleAddItems} sx={utilStyles.cartBtn(cartBtnStyle, disabled)} />
    </Box>
  );
};
